import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
    listLogs as _listLogs,
} from "../../services/log";

import { LIST_LOGS } from "../../app/actionTypes";
import { QueryParameters, ResultQuery } from "../../components/CustomTable";
import { logColumns } from ".";

export const listLogs = createAsyncThunk(
	LIST_LOGS,
	async (queryParameters: QueryParameters, thunkAPI) => {
		try {
            let queryStringParameters = {
                offset: (queryParameters.page*queryParameters.pageSize).toFixed(0),
                limit: (queryParameters.pageSize).toFixed(0),
                search: queryParameters.search || "",
                filters: queryParameters.filters ? JSON.stringify(
                    queryParameters.filters.map((filter: any) => ({
                        column: filter.column.field,
                        operator: filter.operator,
                        value: filter.value
                    }))
                ) : "",
                orderByCollection: queryParameters.orderByCollection ? JSON.stringify(queryParameters.orderByCollection.map((orderBy: any) => ({
                    column: logColumns[orderBy.orderBy].field,
                    orderDirection: orderBy.orderDirection
                }))) : ""
            }
            const response = await _listLogs(queryStringParameters)
            const result: ResultQuery = {
                data: response.logs.map((log) => ({
                    ...log,
                    event: JSON.parse(log.event),
                    response: JSON.parse(log.response),
                })),
                page: Math.round(response.offset/response.limit),
                totalCount: response.totalCount
            }
			return result
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message || error
			})
		}
	}
)

const initialState = {
    logs: 0
}

const slice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        logsAction: (state, action: PayloadAction<number>) => {
            state.logs += action.payload
        }
    }
})

export const actions = slice.actions
export const reducer = slice.reducer