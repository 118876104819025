import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CREATE_GROUP, DELETE_GROUP, LIST_GROUP, LIST_GROUP_PERMISSIONS, READ_GROUP, UPDATE_GROUP, UPDATE_GROUP_PERMISSION } from "../../app/actionTypes";
import { 
    Group,
    createGroup as _createGroup,
    listGroup as _listGroup,
    updateGroup as _updateGroup,
    readGroup as _readGroup,
    deleteGroup as _deleteGroup,
    listGroupPermissions as _listGroupPermissions,
    updateGroupPermission as _updateGroupPermission
} from "../../services/group";
import { QueryParameters, ResultQuery } from "../../components/CustomTable";
import { groupColumns, groupPermissionsColumns } from ".";

export const createGroup = createAsyncThunk(
	CREATE_GROUP,
	async (group: Group, thunkAPI) => {
		try {
            const response = await _createGroup(group)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const listGroup = createAsyncThunk(
	LIST_GROUP,
	async (queryParameters: QueryParameters, thunkAPI) => {
		try {
            let queryStringParameters = {
                offset: (queryParameters.page*queryParameters.pageSize).toFixed(0),
                limit: (queryParameters.pageSize).toFixed(0),
                search: queryParameters.search || "",
                filters: queryParameters.filters ? JSON.stringify(
                    queryParameters.filters.map((filter: any) => ({
                        column: filter.column.field,
                        operator: filter.operator,
                        value: filter.value
                    }))
                ) : "",
                orderByCollection: queryParameters.orderByCollection ? JSON.stringify(queryParameters.orderByCollection.map((orderBy: any) => ({
                    column: groupColumns[orderBy.orderBy].field,
                    orderDirection: orderBy.orderDirection
                }))) : ""
            }
            const response = await _listGroup(queryStringParameters)
            const result: ResultQuery = {
                data: response.groups,
                page: Math.round(response.offset/response.limit),
                totalCount: response.totalCount
            }
			return result
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message || error
			})
		}
	}
)

export const readGroup = createAsyncThunk(
	READ_GROUP,
	async (groupId: number, thunkAPI) => {
		try {
            const response = await _readGroup(groupId)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const deleteGroup = createAsyncThunk(
	DELETE_GROUP,
	async (groupId: number, thunkAPI) => {
		try {
            const response = await _deleteGroup(groupId)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const updateGroup = createAsyncThunk(
	UPDATE_GROUP,
	async (group: Group, thunkAPI) => {
		try {
            const response = await _updateGroup(group)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

interface ListGroupPermissions extends QueryParameters{
    groupId: number;
}
export const listGroupPermissions = createAsyncThunk(
	LIST_GROUP_PERMISSIONS,
	async (queryParameters: ListGroupPermissions, thunkAPI) => {
		try {
            let queryStringParameters = {
                offset: (queryParameters.page*queryParameters.pageSize).toFixed(0),
                limit: (queryParameters.pageSize).toFixed(0),
                search: queryParameters.search || "",
                filters: queryParameters.filters ? JSON.stringify(
                    queryParameters.filters.map((filter: any) => ({
                        column: filter.column.field,
                        operator: filter.operator,
                        value: filter.value
                    }))
                ) : "",
                orderByCollection: queryParameters.orderByCollection ? JSON.stringify(queryParameters.orderByCollection.map((orderBy: any) => ({
                    column: groupPermissionsColumns[orderBy.orderBy].field,
                    orderDirection: orderBy.orderDirection
                }))) : ""
            }
            const response = await _listGroupPermissions(queryStringParameters, queryParameters.groupId)
            const result: ResultQuery = {
                data: response.groupPermissions,
                page: Math.round(response.offset/response.limit),
                totalCount: response.totalCount
            }
			return result
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message || error
			})
		}
	}
)

export const updateGroupPermission = createAsyncThunk(
	UPDATE_GROUP_PERMISSION,
	async (groupPermission: any, thunkAPI) => {
		try {
            const response = await _updateGroupPermission(groupPermission)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

const initialState = {
    groups: {
        data: [],
        page: 0,
        totalCount: 0
    },
    loading: false,
    lastCreatedGroup: null,
    lastUpdatedGroup: null,
    group: null,
    error: null
}

const slice = createSlice({
    name: 'groupmanagement',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(createGroup.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(createGroup.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(createGroup.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.lastCreatedGroup = payload
        }),
        builder.addCase(listGroup.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(listGroup.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(listGroup.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.groups = payload
        }),
        builder.addCase(readGroup.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(readGroup.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(readGroup.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.group = payload
        }),
        builder.addCase(deleteGroup.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(deleteGroup.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(deleteGroup.fulfilled, (state: any) => {
            state.loading = false
        }),
        builder.addCase(updateGroup.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(updateGroup.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(updateGroup.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.lastUpdatedGroup = payload
        })
    }
})

export const actions = slice.actions
export const reducer = slice.reducer