import { Box, TablePagination, useTheme } from "@mui/material"

export default function Pagination({onChangePage, onChangeRowsPerPage, page, rowsPerPage, rowsPerPageOptions, count}: any) {
  const theme = useTheme()
  return (
    <Box display='flex' component="td">
      <TablePagination
        component="div"
        count={count || 0}
        page={page || 0}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage || 10}
        onRowsPerPageChange={onChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        style={{width:"100%", color: theme.palette.text.primary}}
        labelRowsPerPage="Linhas por coluna"
      />
    </Box>
  )
}
