import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { TicketsContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Backdrop, Box, Button, Autocomplete, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { maskCpf, maskPhone } from "../../libs/masks";
import { Add } from "@mui/icons-material";

interface Props extends PropsWithChildren{
}
const validation = (value: string, fieldName: string) => {
    switch (fieldName) {
        case "type":
            return !value.length
        case "classification":
            return false
        case "status":
            return false
        case "mainPhone":
            return value.length < 14
        case "street":
            return value.length < 3
        case "number":
            return !value.length
        case "state":
            return value.length < 2
        case "city":
            return value.length < 3
        case "complement":
            return false
        case "neighborhood":
            return value.length < 3
        case "postalCode":
            return value.length < 8
        default:
            return true
    }
}

const mockClients = [
    {
        id: 1,
        name: "João Teste",
        document: "00000000000" 
    },
    {
        id: 2,
        name: "Maria Teste",
        document: "10000000000" 
    }
]
function Tickets(props: Props){
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)

    const columns = useRef([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Ticket', field: 'nome', filtering: false },
        { title: 'Cliente', field: 'nome', filtering: false },
        { title: 'Tipo de incidente', field: 'telefoneContato', filtering: true },
        { title: 'Assunto', field: 'emailContato', filtering: false },
        { title: 'Data de abertura', field: 'documento', filtering: false },
        { title: 'Última atualização', field: 'status', filtering: false },
        { title: 'Status', field: 'nomeEmpresa', filtering: true },
    ])

    const [payload, setPayload] = useState({
        id: "",
        telecallTicketId: "",
        type: "",
        classification: "",
        status: "",
        subject: "",
        body: "",
        customerId: 0
    })
    const [isCreateOrUpdate, setIsCreateOrUpdate] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [isLoadingCustomer, setIsloadingCustomer] = useState(false)
    const [openCustomerOptions, setOpenCustomerOptions] = useState(false)
    
    const handleOnSave = useCallback(() => {
        setIsCreateOrUpdate(false)
    }, [])

    const handleOnCancel = useCallback(() => {
        setIsCreateOrUpdate(false)
    }, [])

    return(
        <TicketsContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "15%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    TICKETS CADASTRADOS
                </Typography>
            </Box>
            <CustomTable
                title="Lista de tickets cadastrados"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={[]}
                options={{
                    filtering: true
                }}
                style={{margin: "0 2% 0 2%"}}
                actions={[
                    {
                        icon: () => <Add />,
                        tooltip: 'Adicionar',
                        isFreeAction: true,
                        onClick: () => {setIsCreateOrUpdate(!isCreateOrUpdate)},
                    }
                ]}
            />
            <Dialog open={isCreateOrUpdate} maxWidth={false}>
                <DialogTitle id={"mainLabel"} sx={{
                    textAlign: "center",
                    fontSize: 24
                }}>
                    Abertura de ticket
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid xs={3} m={1} >
                            <FormControl fullWidth>
                                <InputLabel id="ticketTypeLabel">Tipo</InputLabel>
                                <Select
                                    labelId="ticketTypeLabel"
                                    id="ticketTypeSelect"
                                    value={payload.type}
                                    label="Tipo"
                                    onChange={(e) => setPayload({...payload, type: e.target.value})}
                                >
                                    <MenuItem value={"type1"}>Tipo 1</MenuItem>
                                    <MenuItem value={"type2"}>Tipo 2</MenuItem>
                                    <MenuItem value={"type3"}>Tipo 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={3} m={1}>
                            <FormControl fullWidth>
                                <InputLabel id="classificationLabel">Classificação</InputLabel>
                                <Select
                                    labelId="classificationLabel"
                                    id="classificationSelect"
                                    value={payload.classification}
                                    label="Classificação"
                                    onChange={(e) => setPayload({...payload, classification: e.target.value})}
                                >
                                    <MenuItem value={"classification1"}>Classificação 1</MenuItem>
                                    <MenuItem value={"classification2"}>Classificação 2</MenuItem>
                                    <MenuItem value={"classification3"}>Classificação 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={3} m={1}>
                            <FormControl fullWidth>
                                <InputLabel id="statusLabel">Status</InputLabel>
                                <Select
                                    labelId="statusLabel"
                                    id="statusSelect"
                                    value={payload.status}
                                    label="Status"
                                    onChange={(e) => setPayload({...payload, status: e.target.value})}
                                >
                                    <MenuItem value={"status1"}>Status 1</MenuItem>
                                    <MenuItem value={"status2"}>Status 2</MenuItem>
                                    <MenuItem value={"status3"}>Status 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} m={1}>
                        <Autocomplete
                            id="customerSearch"
                            fullWidth
                            open={openCustomerOptions}
                            onOpen={() => {
                                setOpenCustomerOptions(true);
                            }}
                            onClose={() => {
                                setOpenCustomerOptions(false);
                            }}
                            isOptionEqualToValue={(option: any, value: any) => option.document === value.document}
                            getOptionLabel={(option: any) => `${option.name} - ${maskCpf(option.document)}`}
                            options={mockClients}
                            loading={isLoadingCustomer}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Clientes"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoadingCustomer ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                            onChange={(_, newValue: any) => {
                                if (newValue) {
                                    setPayload({...payload, customerId: newValue.id});
                                }
                            }}
                        />
                        </Grid>
                        <Grid xs={12} m={1}>
                            <TextField id="subject" label="Assunto" variant="outlined" fullWidth
                                value={payload.subject} onChange={(e) => setPayload({...payload, subject: maskCpf(e.target.value)})}
                                error={validation(payload.subject, "subject")} helperText="* Este campo é obrigatório"
                            />
                        </Grid>
                        <Grid xs={12} m={1}>
                            <TextField id="body" label="Descrição" variant="outlined" fullWidth
                                value={payload.body} onChange={(e) => setPayload({...payload, body: maskPhone(e.target.value)})}
                                error={validation(payload.body, "body")} helperText="* Este campo é obrigatório"
                                multiline rows={4}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{width: "100%", alignItems: "flex-end"}}>
                    <Button id="save" variant="contained" sx={{width: "20%"}}
                        onClick={handleOnSave}
                    >
                        Salvar
                    </Button>
                    <Button variant="contained" color="error"  sx={{width: "20%"}}
                        onClick={handleOnCancel}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
                <Backdrop
                    sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </TicketsContainer>
    )
}

export default Tickets;