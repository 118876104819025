import { PropsWithRef, forwardRef } from "react";
import { CustomMaterialTable } from "./styles";
import Pagination from "../Pagination";
import {
    AddBox, Check, Clear, DeleteOutline,
    ChevronRight, Edit, SaveAlt, FilterList,
    FirstPage, LastPage, Remove, ViewColumn,
    ChevronLeft, Search, ArrowUpward, Refresh
} from "@mui/icons-material";

interface RowData extends Object{}

interface Props extends PropsWithRef<any>{
    key?: any;
    title?: string;
    tableRef?: any;
    standartTableRef?: any;
    tableIcons?: any;
    columns: any;
    getData: any;
    onRowAdd?: any;
    onRowUpdate?: any;
    onRowDelete?: any;
    isDeleteHidden?: any;
    options?: any;
    pageSizeOptions: number[];
    pageSize: any;
    page: any;
    onRefresh?: any;
    Refresh?: any;
    actions?: any;
    onChangePage?: any;
    _onChangePage?: any;
    onChangeRowsPerPage?: any;
    _onChangeRowsPerPage?: any;
    detailPanel?: any;
    style?: any;
    isLoading?: any;
    cellEditable?: any;
}

interface Filter {
    column: string;
    operator: string;
    value: any;
}

interface OrderByCollection {
    orderBy: number;
    orderDirection: string;
    sortOrder: number;
}

export interface QueryParameters {
    page: number;
    pageSize: number;
    filters?: Filter[];
    totalCount?: number;
    search?: string;
    orderByCollection?: OrderByCollection[];
}

export interface ResultQuery {
    data: any[];
    page: number;
    totalCount: number;
}

const TABLE_ICONS = {
    Add: forwardRef((props:any, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props:any, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props:any, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props:any, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props: any & { level?: number; row?: RowData }, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props:any, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props:any, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props:any, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props:any, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props:any, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props:any, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props:any, ref: any) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props:any, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props:any, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props:any, ref: any) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props:any, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props:any, ref: any) => <ViewColumn {...props} ref={ref} />),
    Refresh: forwardRef((props:any, ref: any) => <Refresh {...props} ref={ref} />),
};

const CustomTable = ({
    key, title, tableRef, standartTableRef,
    columns, getData, onRowAdd, onRowUpdate, onRowDelete,
    isDeleteHidden, options, pageSizeOptions, pageSize,
    page, onRefresh, actions, onChangePage,
    _onChangePage, onChangeRowsPerPage, _onChangeRowsPerPage,
    detailPanel, style, isLoading, cellEditable, editable
}: Props) => {
    return (
        <CustomMaterialTable
            key={key}
            tableRef={tableRef || standartTableRef}
            title={title}
            columns={columns}
            data={getData}
            editable={{
                ...editable,
                onRowAdd: onRowAdd,
                onRowUpdate: onRowUpdate,
                onRowDelete: onRowDelete,
                isDeleteHidden: isDeleteHidden,
            }}
            page={page}
            options={!options ? {
                    actionsColumnIndex: -1,
                    filtering: true,
                    pageSizeOptions: pageSizeOptions || [10,20,50],
                    paging: true,
                    pageSize: pageSize,
                }
                :
                {
                    actionsColumnIndex: -1,
                    ...options,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptions
                }
            }
            actions={[
                !onRefresh && {
                    icon: Refresh,
                    tooltip: 'Atualizar',
                    isFreeAction: true,
                    onClick: () => !onRefresh ? standartTableRef.current&&standartTableRef.current.onQueryChange() : onRefresh(),
                },
                ...(!actions?[]:actions)
            ]}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                    addTooltip: "Adicionar",
                    deleteTooltip: "Apagar",
                    editTooltip: "Editar",
                    editRow:{
                        deleteText: "Tem certeza que deseja apagar?",
                        saveTooltip: "Salvar",
                        cancelTooltip: "Cancelar"
                    }
                },
                toolbar: {
                    searchTooltip: 'Pesquisar',
                    searchPlaceholder: "Pesquisar"
                },
                header:{ actions: "Ações"},
            }}
            components={{
                Pagination: props => {return(
                    <Pagination
                        onChangePage={props.onPageChange}
                        onChangeRowsPerPage={props.onRowsPerPageChange}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        count={props.count}
                    />
                )}
            }}
            onPageChange={onChangePage || _onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage||_onChangeRowsPerPage}
            detailPanel={detailPanel}
            style={style}
            isLoading={isLoading}
            icons={TABLE_ICONS}
            cellEditable={cellEditable}
        />
    );
};

export default CustomTable;