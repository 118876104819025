import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { GroupManagementContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Add, Edit } from "@mui/icons-material";
import { 
    Backdrop, Box, Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Typography, Tabs, Tab
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import AutoCompletePermissions from "../../components/AutoCompletePermissions";
import { createGroup, deleteGroup, listGroup, listGroupPermissions, updateGroup, updateGroupPermission } from "./slice";
import { useAppDispatch } from "../../app/hooks";

interface Props extends PropsWithChildren{
}
const validation = (value: string, fieldName: string) => {
    switch (fieldName) {
        case "name":
            return value.length < 3 || !value.includes(' ')
        case "description":
            return value.length < 3 || !value.includes('@')
        case "document":
            return value.length !== 14 && value.length !== 18
        case "mainPhone":
            return value.length < 14
        case "street":
            return value.length < 3
        case "number":
            return !value.length
        case "state":
            return value.length < 2
        case "city":
            return value.length < 3
        case "complement":
            return false
        case "neighborhood":
            return value.length < 3
        case "postalCode":
            return value.length < 8
        default:
            return true
    }
}

const mockPermissions = [
    {
        id: 1,
        name: "Permissão 1",
        type: "entity"
    },
    {
        id: 2,
        name: "Permissão 2",
        type: "enable"
    }
]

export const groupColumns = [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'Nome do grupo', field: 'name' },
    { title: 'Descrição', field: 'description' },
    { title: 'Data de cadastro', field: 'createdAt', type: "datetime", editable: "never" },
]

export const groupPermissionsColumns = [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'Id Grupo', field: 'group.id', hidden: true },
    { title: 'Id Permissão', field: 'permission.id', hidden: true, editable: "never" },
    { title: 'Nome do grupo', field: 'group.name', filtering: false, editable: "never" },
    { title: 'Permissão', field: 'permission.name', minWidth: 220, editable: "never" },
    { title: 'Descrição da permissão', field: 'permission.description', filtering: false, editable: "never" },
    { title: 'Criar', field: 'create', type: 'boolean', filtering: false,
        editable: (columnDef: any, rowData: any) => (rowData&&rowData['permission.type'] === "entity")
    },
    { title: 'Ler', field: 'read', type: 'boolean', filtering: false,
        editable: (columnDef: any, rowData: any) => (rowData&&rowData['permission.type'] === "entity")
    },
    { title: 'Atualizar', field: 'update', type: 'boolean', filtering: false,
        editable: (columnDef: any, rowData: any) => (rowData&&rowData['permission.type'] === "entity")
    },
    { title: 'Deletar', field: 'delete', type: 'boolean', filtering: false,
        editable: (columnDef: any, rowData: any) => (rowData&&rowData['permission.type'] === "entity")
    },
    { title: 'Ativar', field: 'enabled', type: 'boolean', filtering: false,
        editable: (columnDef: any, rowData: any) => (rowData&&rowData['permission.type'] === "enable")
    },
    { title: 'Data de cadastro', field: 'createdAt', type: "datetime", editable: "never", filtering: false },
]

function GroupManagement(props: Props){
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)
    const appDispatch = useAppDispatch()
    const columns = useRef(groupColumns)
    
    const [payload, setPayload] = useState({
        id: 0,
        name: "",
        description: ""
    })

    const columnsGroupPermission = useRef(groupPermissionsColumns)

    const [isCreateGroup, setIsCreateGroup] = useState(false)
    const [isUpdateGroup, setIsUpdateGroup] = useState(false)
    const [groupUpdateTab, setGroupUpdateTab] = useState(0)
    const [isLoading, setIsloading] = useState(false)
    const tableRef = useRef<any>(null);

    const handleOnSave = useCallback(async () => {
        try {
            if (isCreateGroup) {
                await appDispatch(createGroup(payload)).unwrap()
                setIsCreateGroup(false)
            }
            if (isUpdateGroup) {
                await appDispatch(updateGroup(payload)).unwrap()
                setIsUpdateGroup(false)
            }
            tableRef.current&&tableRef.current.onQueryChange()
        } catch (error) {
            console.log(error)
        }
    }, [payload])

    const handleOnCancel = useCallback(() => {
        setIsCreateGroup(false)
        setIsUpdateGroup(false)
    }, [])

    const [pageSizePermissions, setPageSizePermissions] = useState(10)
    const [pagePermissions, setPagePermissions] = useState(0)
    const tableRefPermissions = useRef<any>(null);
    return(
        <GroupManagementContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "16%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    Grupos de acesso
                </Typography>
            </Box>
            <CustomTable
                title="Gerencimento de acessos"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={async (query: any) => await appDispatch(listGroup(query)).unwrap()}
                options={{
                    filtering: false
                }}
                style={{margin: "0 2% 0 2%"}}
                actions={[
                    {
                        icon: () => <Add />,
                        tooltip: 'Adicionar',
                        isFreeAction: true,
                        onClick: () => {setIsCreateGroup(!isCreateGroup)},
                    },
                    {
                        icon: () => <Edit />,
                        tooltip: 'Editar',
                        isFreeAction: false,
                        onClick: (event: any, rowData: any) => {
                            setPayload({
                                id: rowData.id,
                                name: rowData.name,
                                description: rowData.description
                            })
                            setIsUpdateGroup(true)
                        },
                    }
                ]}
                standartTableRef={tableRef}
                onChangePage={(_page: number, _pageSize: number) => {
                    setPage(_page)
                    setPageSize(_pageSize)
                }}
                onRowDelete={async (rowData: any) => {
                    try {
                        await appDispatch(deleteGroup(rowData.id)).unwrap()
                    } catch (error) {
                    }
                }}
            />
            <Dialog open={isCreateGroup || isUpdateGroup} maxWidth={false}>
                <DialogTitle id={"mainLabel"} sx={{
                    textAlign: "center",
                    fontSize: 24
                }}>
                    Grupo de acesso
                </DialogTitle>
                <DialogContent sx={{width: "95vw", height: "80vh"}}>
                    <Box>
                        <Tabs value={groupUpdateTab} onChange={(_, newValue) => setGroupUpdateTab(newValue)} aria-label="group tabs">
                            <Tab label="Grupo"/>
                            <Tab label="Permissões" disabled={!payload.id}/>
                        </Tabs>
                    </Box>
                    <Grid container padding={"10px 0 0 0"}>
                        {groupUpdateTab === 0 ?
                            <>
                                <Grid xs={4} m={1}>
                                    <TextField id="name" label="Nome" variant="outlined" fullWidth
                                        value={payload.name} onChange={(e) => setPayload({...payload, name: e.target.value})}
                                        error={validation(payload.name, "name")} helperText="* Este campo é obrigatório"
                                    />
                                </Grid>
                                <Grid xs={12} m={1}>
                                    <TextField id="description" label="Descrição" variant="outlined" fullWidth
                                        value={payload.description} onChange={(e) => setPayload({...payload, description: e.target.value})}
                                        error={validation(payload.description, "description")} helperText="* Este campo é obrigatório"
                                        multiline rows={4}
                                    />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid xs={12}>
                                    <CustomTable
                                        title="Permissões do grupo"
                                        pageSizeOptions={[10,50,100]}
                                        pageSize={pageSizePermissions}
                                        page={pagePermissions}
                                        columns={columnsGroupPermission.current}
                                        getData={async (query: any) => await appDispatch(listGroupPermissions({...query, groupId: payload.id})).unwrap()}
                                        options={{
                                            filtering: false
                                        }}
                                        style={{margin: "0 2% 0 2%"}}
                                        standartTableRef={tableRefPermissions}
                                        onChangePage={(_page: number, _pageSize: number) => {
                                            setPagePermissions(_page)
                                            setPageSizePermissions(_pageSize)
                                        }}
                                        onRowUpdate={async (newData: any) => await appDispatch(updateGroupPermission(newData)).unwrap()}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions sx={{width: "100%", alignItems: "flex-end"}}>
                    <Button id="save" variant="contained" fullWidth  sx={{width: "20%"}}
                        onClick={handleOnSave}
                    >
                        Salvar
                    </Button>
                    <Button variant="contained" color="error"  fullWidth  sx={{width: "20%"}}
                        onClick={handleOnCancel}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </GroupManagementContainer>
    )
}

export default GroupManagement;