import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    mock: 0
}

const slice = createSlice({
    name: 'mock',
    initialState,
    reducers: {
        mockAction: (state, action: PayloadAction<number>) => {
            state.mock += action.payload
        }
    }
})

export const actions = slice.actions
export const reducer = slice.reducer