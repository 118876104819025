import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes";
import PublicRoutes from "./components/PublicRoutes";
import MenuBar from "./components/MenuBar";
import Mock from "./pages/Mock";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import Tickets from "./pages/Tickets";
import Portability from "./pages/Portability";
import Companies from "./pages/Companies";
import Products from "./pages/Products";
import CustomerCatalog from "./pages/CustomerCatalog";
import UserManagement from "./pages/UserManagement";
import GroupManagement from "./pages/GroupManagement";
import Logs from "./pages/Logs";
import Profile from "./pages/Profile";
import Subsidiaries from "./pages/Subsidiaries";
import Clients from "./pages/Clients";

import { Amplify } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import Splash from "./pages/Splash";
import { Backdrop } from "@mui/material";
import { signOutUser } from "./pages/SignIn/slice";
import SubsidiaryProducts from "./pages/SubsidiaryProducts";
import ClientProducts from "./pages/ClientProducts";

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
        {
            name: "tex",
            endpoint: import.meta.env.VITE_API_URL,
            region: import.meta.env.VITE_REGION
        }
    ]
  }
});

export default function App() {
    const authenticated:boolean = useAppSelector((state: RootState) => state.sessionReducer.authenticated);
    const sessionChecked:boolean = useAppSelector((state: RootState) => state.sessionReducer.sessionChecked);
    const isLoading:boolean = useAppSelector((state: RootState) => state.splashReducer?.isLoading);
    const appDispatch = useAppDispatch()
    return (
        <div>
            <Routes>
                <Route element={<PrivateRoutes authenticated={authenticated&&sessionChecked} unauthenticatedRoute="/signin"/>}>
                    <Route path="/" element={<MenuBar signOut={async() => {
                        await appDispatch(signOutUser()).unwrap()
                    }}/>}>
                        <Route path="/mock" element={<Mock/>}/> 
                        <Route path="/dashboard" element={<Dashboard/>}/> 
                        <Route path="/customers" element={<Customers/>}/> 
                        <Route path="/subsidiaries" element={<Subsidiaries/>}/> 
                        <Route path="/clients" element={<Clients/>}/> 
                        <Route path="/products" element={<Products/>}/> 
                        <Route path="/subsidiary-products" element={<SubsidiaryProducts/>}/> 
                        <Route path="/client-products" element={<ClientProducts/>}/> 
                        <Route path="/tickets" element={<Tickets/>}/> 
                        <Route path="/portability" element={<Portability/>}/> 
                        <Route path="/companies" element={<Companies/>}/> 
                        <Route path="/customercatalog" element={<CustomerCatalog/>}/> 
                        <Route path="/usermanagement" element={<UserManagement/>}/> 
                        <Route path="/groupmanagement" element={<GroupManagement/>}/> 
                        <Route path="/logs" element={<Logs/>}/> 
                        <Route path="/profile" element={<Profile/>}/> 
                    </Route>
                </Route>
                <Route element={<PublicRoutes authenticated={authenticated&&sessionChecked} authenticatedRoute="/"/>}>
                    <Route path="/signin" element={<SignIn/>}/> 
                </Route>
            </Routes>
            <Backdrop open={isLoading}>
                <Splash/>
            </Backdrop>
        </div>
    );
}
