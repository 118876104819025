import { combineReducers } from "@reduxjs/toolkit";
import type { Reducer } from "@reduxjs/toolkit";

import { reducer as mockReducer } from "../pages/Mock/slice"
import sessionReducer from "./sessionManager/sessionSlice"
import splashReducer from "../pages/Splash/slice"
import signInReducer from "../pages/SignIn/slice"

const rootReducer: Reducer = combineReducers({
  mockReducer,
  sessionReducer,
  splashReducer,
  signInReducer
})

export default rootReducer