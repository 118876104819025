import {
    styled, Container, AppBar as MuiAppBar,
    Toolbar as MuiToolbar, IconButton as MuiIconButton, Divider as MuiDivider,
    List as MuiList, ListItem as MuiListItem,
    ListItemButton as MuiListItemButton, ListItemIcon as MuiListItemIcon,
    ListItemText as MuiListItemText, Box
} from "@mui/material";
import {
    Menu, Dashboard,
    SupportAgent, Person, MobileScreenShare, Business, ListAlt,
    List as MuiListIcon, AssignmentInd, People, GroupAdd,
    PermContactCalendar, LogoDev, ShoppingCart
} from '@mui/icons-material';

const appBarHeight = 80;

export const MenuContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: "row",
  paddingTop: appBarHeight,
  minWidth: "100vw",
  minHeight: "100vh",
}))

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: appBarHeight
}));

export const Toolbar = styled(MuiToolbar)(({}) => ({
  height: appBarHeight,
  flex: 1,
  justifyContent: "space-between"
}))

export const IconButton = styled(MuiIconButton)(({}) => ({
}))

export const MenuIcon = styled(Menu)(({}) => ({
}))

export const Divider = styled(MuiDivider)(() => ({}))
export const List = styled(MuiList)(() => ({}))
export const ListItem = styled(MuiListItem)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}))
export const ListItemButton = styled(MuiListItemButton)(() => ({}))
export const ListItemIcon = styled(MuiListItemIcon)(() => ({}))
export const ListItemText = styled(MuiListItemText)(() => ({}))

export const DrawerBox = styled(Box)(({theme}) => ({
  borderRight: '1px solid #00000022',
}))

export const DashboardIcon = styled(Dashboard)(() => ({}))
export const ShoppingCartIcon = styled(ShoppingCart)(() => ({}))
export const PersonIcon = styled(Person)(() => ({}))
export const SupportAgentIcon = styled(SupportAgent)(() => ({}))
export const MobileScreenShareIcon = styled(MobileScreenShare)(() => ({}))
export const BusinessIcon = styled(Business)(() => ({}))
export const ListAltIcon = styled(ListAlt)(() => ({}))
export const ListIcon = styled(MuiListIcon)(() => ({}))
export const AssignmentIndIcon = styled(AssignmentInd)(() => ({}))
export const PeopleIcon = styled(People)(() => ({}))
export const GroupAddIcon = styled(GroupAdd)(() => ({}))
export const PermContactCalendarIcon = styled(PermContactCalendar)(() => ({}))
export const LogoDevIcon = styled(LogoDev)(() => ({}))