import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
    interface PaletteOptions {
        tertiary: PaletteColorOptions;
        menuSelected: PaletteColorOptions;
    }
}
  
export const customTheme = createTheme({
    palette: {
        primary: {
            main: "#53119d"
        },
        secondary: {
            main: "#ffffff"
        },
        tertiary: {
            main: "#0e0004"
        },
        menuSelected: {
            main: "#0e000444"
        }
    }
});