/**
 * MOCK PAGE
 */

export const MOCK_ACTION = 'MOCK/MOCK_ACTION'

/**
 * SIGN-IN PAGE
 */

export const SIGN_IN = 'SIGN-IN/SIGN_IN'
export const SIGN_OUT = 'SIGN-IN/SIGN_OUT'
export const FORGOT_PASSWORD = 'SIGN-IN/FORGOT_PASSWORD'
export const RESET_PASSWORD = 'SIGN-IN/RESET_PASSWORD'
export const COMPLETE_NEW_PASSWORD = 'SIGN-IN/COMPLETE_NEW_PASSWORD'
export const TOGGLE_REMEMBER_PASSWORD = 'SIGN-IN/TOGGLE_REMEMBER_PASSWORD'
export const SAVE_USERNAME_PASSWORD = 'SIGN-IN/SAVE_USERNAME_PASSWORD'

/**
 * SPLASH PAGE
 */

export const TOGGLE_IS_LOADING = 'SPLASH/TOGGLE_IS_LOADING'

/**
 * USER MANAGEMENT PAGE
 */

export const CREATE_USER = 'USER-MANAGEMENT/CREATE_USER'
export const LIST_USER = 'USER-MANAGEMENT/LIST_USER'
export const READ_USER = 'USER-MANAGEMENT/READ_USER'
export const UPDATE_USER = 'USER-MANAGEMENT/UPDATE_USER'
export const DELETE_USER = 'USER-MANAGEMENT/DELETE_USER'

/**
 * GROUP MANAGEMENT PAGE
 */

export const CREATE_GROUP = 'GROUP-MANAGEMENT/CREATE_GROUP'
export const LIST_GROUP = 'GROUP-MANAGEMENT/LIST_GROUP'
export const READ_GROUP = 'GROUP-MANAGEMENT/READ_GROUP'
export const UPDATE_GROUP = 'GROUP-MANAGEMENT/UPDATE_GROUP'
export const DELETE_GROUP = 'GROUP-MANAGEMENT/DELETE_GROUP'
export const LIST_GROUP_PERMISSIONS = 'GROUP-MANAGEMENT/LIST_GROUP_PERMISSIONS'
export const UPDATE_GROUP_PERMISSION = 'GROUP-MANAGEMENT/UPDATE_GROUP_PERMISSION'

/**
 * LOG PAGE
 */

export const LIST_LOGS = 'GROUP-MANAGEMENT/LIST_LOGS'

/**
 * USER CATALOG PAGE
 */

export const CREATE_PRODUCT = 'PRODUCT-CATALOG/CREATE_PRODUCT'
export const LIST_PRODUCTS = 'PRODUCT-CATALOG/LIST_PRODUCTS'
export const READ_PRODUCT = 'PRODUCT-CATALOG/READ_PRODUCT'
export const UPDATE_PRODUCT = 'PRODUCT-CATALOG/UPDATE_PRODUCT'
export const DELETE_PRODUCT = 'PRODUCT-CATALOG/DELETE_PRODUCT'
