import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { CustomersContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, InputLabel, MenuItem, Select, Tab, Tabs,
  TextField, Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { maskCpf, maskPhoneNumber, maskCustomLink } from "../../libs/masks"
import { Add } from "@mui/icons-material";
import { instanceTeleclinica } from "../../libs/instanceTeleclinica";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
interface Props extends PropsWithChildren {
}

const validation = (value: string, fieldName: string) => {
  switch (fieldName) {
    case "subsidiaryId":
      return value === ""
    case "name":
      return value.length < 3 || !value.includes(' ')
    case "mobilePhone":
      return !value.length
    case "email":
      return value.length < 3 || !value.includes('@')
    case "document":
      return value.length !== 14 && value.length !== 18
    case "customLink":
      return value.length < 3 || value.includes(' ')
    default:
      return true
  }
}

function Clients(props: Props) {
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const columns = useRef([
    { title: 'Id', field: 'id', hidden: false, filtering: false },
    { title: 'Nome', field: 'name', filtering: false },
    { title: 'E-mail', field: 'email', filtering: false },
    {
      title: 'CPF/CNPJ', field: 'document',
      render: (rowData: any) => {
        return maskCpf(rowData.document)
      }, filtering: false
    },
    { title: 'Link personalizado', field: 'customLink', filtering: false },
    { title: 'Status', field: 'status', filtering: false },
    { title: 'Criado em', field: 'createdAt', type: "date", filtering: false },
    { title: 'Atualizado em', field: 'updatedAt', type: "date", filtering: false },
  ])

  const [payload, setPayload] = useState({
    subsidiaryId: "",
    name: "",
    mobilePhone: "",
    email: "",
    document: "",
    customLink: "",
    companyType: "",
    status: "active"
  })
  const [isCreateOrUpdate, setIsCreateOrUpdate] = useState(false)
  const [customerUpdateTab, setCustomerUpdateTab] = useState(0)
  const [isLoading, setIsloading] = useState(false)
  const [isRequestMade, setIsRequestMade] = useState(false)

  const token: string = useAppSelector((state: RootState) => state.sessionReducer.session.signInUserSession.idToken.jwtToken);

  const validateForm = useCallback(() => {
    return payload.name !== "" && payload.mobilePhone !== "" && payload.email !== "" && payload.document !== ""
      && payload.customLink !== "" && (payload.document.length === 14 || payload.document.length === 18)
  }, [payload])

  const handleOnSave = useCallback(async () => {
    setIsCreateOrUpdate(true)

    const client = {
      subsidiaryId: payload.subsidiaryId,
      name: payload.name,
      email: payload.email,
      document: payload.document,
      mobilePhone: payload.mobilePhone,
      customLink: payload.customLink,
      status: payload.status
    }

    await instanceTeleclinica.post("/client", client, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(res => {
        setIsRequestMade(!isRequestMade)
        setIsCreateOrUpdate(!isCreateOrUpdate)
        setCustomerUpdateTab(0)
      })
      .catch(er => {
        setIsCreateOrUpdate(false)
        setCustomerUpdateTab(0)
      })

  }, [isRequestMade, isCreateOrUpdate, customerUpdateTab, payload])

  const handleOnCancel = useCallback(() => {
    setIsCreateOrUpdate(false)
    setCustomerUpdateTab(0)
  }, [])

  useEffect(() => {
    if (!isRequestMade) {
      setIsloading(true)
      instanceTeleclinica.get(`/clients`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then((res) => {
          setIsloading(false)
          setData(res.data.clients)
          setIsRequestMade(!isRequestMade)
        })
    }
  }, [data, isRequestMade, isLoading])

  return (
    <CustomersContainer maxWidth={false} disableGutters>
      <Box style={{
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: 15,
        alignItems: "center",
        width: "15%", height: 100, minWidth: 200
      }}>
        <Typography fontSize={20}>
          VENDEDORES
        </Typography>
      </Box>
      <CustomTable
        title="Lista de vendedores (client)"
        pageSizeOptions={[10, 50, 100]}
        pageSize={pageSize}
        page={page}
        columns={columns.current}
        getData={data}
        options={{
          filtering: true
        }}
        isLoading={isLoading}
        style={{ margin: "0 2% 0 2%" }}
        actions={[
          {
            icon: () => <Add />,
            tooltip: 'Adicionar',
            isFreeAction: true,
            onClick: () => { setIsCreateOrUpdate(!isCreateOrUpdate) }
          }
        ]}
      />
      <Dialog open={isCreateOrUpdate} maxWidth={false}>
        <DialogTitle id={"mainLabel"} sx={{
          textAlign: "center",
          fontSize: 24
        }}>
          Informações necesárias para cadastro de um client
        </DialogTitle>
        <DialogContent sx={{ width: "95vw", height: "80vh" }}>
          <Box>
            <Tabs value={customerUpdateTab} onChange={(e, newValue) => setCustomerUpdateTab(newValue)} aria-label="basic tabs example">
              <Tab label="Geral" />
            </Tabs>
          </Box>
          <Grid container padding={"10px 0 0 0"}>
            <Grid xs={4} m={1}>
              <TextField id="subsidiaryId" label="Id da Filial" variant="outlined" fullWidth
                value={payload.subsidiaryId} onChange={(e) => setPayload({ ...payload, subsidiaryId: e.target.value })}
                error={validation(payload.subsidiaryId, "subsidiaryId")} helperText="* Campo obrigatório" type="number"
              />
            </Grid>
            <Grid xs={4} m={1}>
              <TextField id="name" label="Nome Completo" variant="outlined" fullWidth
                value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                error={validation(payload.name, "name")} helperText="* Campo obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="document" label="CPF/CNPJ" variant="outlined" fullWidth
                value={payload.document} onChange={(e) => setPayload({ ...payload, document: maskCpf(e.target.value) })}
                error={validation(payload.document, "document")} helperText="* Campo obrigatório"
              />
            </Grid>
            {
              payload.document.length === 18 ?
                <Grid xs={3} m={1}>
                  <FormControl fullWidth>
                    <InputLabel id="input-select">Tipo de empresa</InputLabel>
                    <Select
                      id="company-type-select"
                      value={payload.companyType}
                      label="companyType"
                      onChange={(e) => setPayload({ ...payload, companyType: e.target.value })}
                    >
                      <MenuItem value={"MEI"}>MEI</MenuItem>
                      <MenuItem value={"LIMITED"}>LIMITED</MenuItem>
                      <MenuItem value={"INDIVIDUAL"}>INDIVIDUAL</MenuItem>
                      <MenuItem value={"ASSOCIATION"}>ASSOCIATION</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                : <></>
            }
            <Grid xs={4} m={1}>
              <TextField id="email" label="E-mail" variant="outlined" fullWidth
                value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                error={validation(payload.email, "email")} helperText="* Campo obrigatório"
              />
            </Grid>
            <Grid xs={4} m={1}>
              <TextField id="mobilePhone" label="Telefone" variant="outlined" fullWidth
                value={maskPhoneNumber(payload.mobilePhone)} onChange={(e) => setPayload({ ...payload, mobilePhone: e.target.value })}
                error={validation(payload.mobilePhone, "mobilePhone")} helperText="* Campo obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="customLink" label="Link personalizado" variant="outlined" fullWidth
                value={maskCustomLink(payload.customLink)} onChange={(e) => setPayload({ ...payload, customLink: e.target.value })}
                error={validation(payload.customLink, "customLink")} helperText="* Campo obrigatório"
              />
            </Grid>
            <Grid xs={4} m={1}>
              <FormControl fullWidth>
                <InputLabel id="input-select">Status</InputLabel>
                <Select
                  id="status-select"
                  value={payload.status}
                  label="Status"
                  onChange={(e) => setPayload({ ...payload, status: e.target.value })}
                >
                  <MenuItem value={"active"}>Ativo</MenuItem>
                  <MenuItem value={"inactive"}>Inátivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ width: "100%", alignItems: "flex-end" }}>
          <Button id="save" variant="contained" fullWidth sx={{ width: "20%" }}
            onClick={handleOnSave} disabled={!validateForm()}
          >
            Salvar
          </Button>
          <Button variant="contained" color="error" fullWidth sx={{ width: "20%" }}
            onClick={handleOnCancel}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </CustomersContainer>
  )
}

export default Clients;