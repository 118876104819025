import React, { useEffect, useState } from "react";
import {
    AppBar, MenuContainer, Toolbar, IconButton,
    MenuIcon, Divider, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, DrawerBox, DashboardIcon,
    PersonIcon, SupportAgentIcon, MobileScreenShareIcon,
    ListAltIcon, ListIcon, AssignmentIndIcon, PeopleIcon,
    GroupAddIcon, PermContactCalendarIcon, LogoDevIcon,
    ShoppingCartIcon
} from "./styles";

import {
    Accordion, AccordionDetails, AccordionSummary, Avatar, Box,
    CssBaseline, Menu, MenuItem, Tooltip, useTheme
} from "@mui/material";
import {
    Outlet
} from "react-router-dom";
import { Logout, SubdirectoryArrowRight, ExpandMore } from "@mui/icons-material";
import {
    useNavigate,
} from "react-router-dom";

const menuItems = [
    {
        name: "DashBoard",
        path: "/dashboard",
        icon: <DashboardIcon color="primary"/>,
        children: []
    },
    {
        name: "Clientes",
        path: "/subsidiaries",
        icon: <PersonIcon color="primary"/>,
        children: [
            {
                name: "Filiais",
                path: "/subsidiaries",
                icon: <AssignmentIndIcon color="primary"/>
            },
            {
                name: "Vendedores",
                path: "/clients",
                icon: <AssignmentIndIcon color="primary"/>
            },
            {
                name: "Compradores",
                path: "/customers",
                icon: <AssignmentIndIcon color="primary"/>
            }
        ]
    },
    {
        name: "Produtos",
        path: "/products",
        icon: <ShoppingCartIcon color="primary"/>,
        children: [
            {
                name: "Produtos",
                path: "/products",
                icon: <AssignmentIndIcon color="primary"/>
            },
            {
                name: "Produtos Subsidiary",
                path: "/subsidiary-products",
                icon: <AssignmentIndIcon color="primary"/>
            },
            {
                name: "Produtos Client",
                path: "/client-products",
                icon: <AssignmentIndIcon color="primary"/>
            },
        ]
    },
]

function MenuBar({signOut}: any){
    const theme: any = useTheme();  
    const [open, setOpen] = useState(true)
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const openProfile = Boolean(anchorEl);
    const [option, setOption] = useState<any>(null)
    const navigate = useNavigate();
    useEffect(() => {
        if(option){
            navigate(option.path)
        }
    }, [option])
    const renderItem = (item: any, onClick: any): JSX.Element => (
        <ListItemButton
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
            }}
            onClick={onClick}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: 'center',
                }}
            >
                {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} sx={{ 
                display: 'flex',
                marginLeft: open ? 5 : 0,
                width: open ? "auto" : 0,
                height: 20,
                opacity: open ? 1 : 0,
                transition: 'width 0.8s ease, margin-left 0.8s ease',
                whiteSpace: 'nowrap'
            }} />
        </ListItemButton>
    )
    return(
        <MenuContainer
            maxWidth={false}
            disableGutters={true}
        >
            <CssBaseline enableColorScheme />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{
                            marginRight: 5
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Tooltip title="Perfil">
                        <IconButton
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                            size="small"
                            aria-controls={openProfile ? 'perfil' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openProfile ? 'true' : undefined}
                        >
                            <Avatar sx={{
                                width: 32, height: 32,
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.secondary.main
                            }}></Avatar>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <DrawerBox >
                <List sx={{minWidth: 0}}>
                    {menuItems.map((item, index) => (
                        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                {item.children.length ?
                                    <Accordion disableGutters elevation={0} expanded={option&&option.name === item.name} >
                                        <Tooltip title={item.name} placement="right" >
                                            <AccordionSummary
                                                expandIcon={option&&option.name === item.name ? <ExpandMore/> : null}
                                                aria-controls="menu-with-children"
                                                id="menu-with-children"
                                                sx={{
                                                    padding: 0, margin: 0, height: 48,
                                                    backgroundColor: option&&option.name === item.name ? theme.palette.menuSelected.main : '#ffffff00'
                                                }}
                                            >
                                                {renderItem(item, () => setOption(option&&option.name === item.name ? null : {name: item.name}))}
                                            </AccordionSummary>
                                        </Tooltip>
                                        <AccordionDetails sx={{padding: 0, display: option&&option.name === item.name ? "block" : "none"}}>
                                            {item.children.map((item2, index2) => (
                                                <ListItem key={index2} disablePadding sx={{ display: 'block'}}>
                                                    {index2 === 0 && <Divider />}
                                                    <Box sx={{
                                                        display: "flex", flexDirection: 'row', justifyContent: "center",
                                                        alignItems: "center", paddingLeft: 3,
                                                        backgroundColor: option&&option.childName === item2.name ? theme.palette.menuSelected.main : '#ffffff00'
                                                    }}>
                                                        <SubdirectoryArrowRight/>
                                                        <Tooltip title={item2.name} placement="right" >
                                                            {renderItem(item2, () => setOption({name: item.name, childName: item2.name, path: item2.path}))}
                                                        </Tooltip>
                                                    </Box>
                                                    <Divider />
                                                </ListItem>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                  :
                                    <Tooltip title={item.name} placement="right" >
                                        <Box sx={{
                                            display: "flex", flexDirection: 'row', justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: option&&option.name === item.name ? theme.palette.menuSelected.main : '#ffffff00'
                                        }}>
                                            {renderItem(item, () => setOption({name: item.name, path: item.path}))}
                                        </Box>
                                    </Tooltip>
                                }
                            <Divider />
                        </ListItem>
                    ))}
                </List>
            </DrawerBox>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openProfile}
                onClose={() => {setAnchorEl(null)}}
                onClick={() => {setAnchorEl(null)}}
                PaperProps={{
                    elevation: 0,
                    sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    setOption(null)
                    navigate('/profile')
                }}>
                    <Avatar sx={{
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.tertiary.main
                    }}/> Perfil
                </MenuItem>
                <MenuItem onClick={async () => {
                    await signOut()
                    navigate('/signin')
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" sx={{
                        color: theme.palette.tertiary.main,
                    }}/>
                    </ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
            <Box
                sx={{
                    flex: 1,
                }}
            >
                <Outlet/>
            </Box>
        </MenuContainer>
    )
}

export default MenuBar;