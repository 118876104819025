import Cookies from 'js-cookie';

import { 
	GET_SESSION_ERROR,
	GET_SESSION_SUCCESS,
	GET_USER_SESSION_ERROR,
	GET_USER_SESSION_SUCCESS,
	SESSION_CHECKED_ERROR,
	SESSION_CHECKED_SUCCESS,
	USER_CHECKED_ERROR,
	USER_CHECKED_SUCCESS,
	CLEAR_SESSION
} from './actionTypes';
import { sessionActions } from './sessionSlice';

const USER_SESSION = 'USER-SESSION';
const USER_DATA = 'USER_DATA';

async function refreshFromLocalStorage(store: any): Promise<void>{
	try {
		const session = await loadSession();
		store.dispatch(sessionActions[CLEAR_SESSION]());
		store.dispatch(sessionActions[GET_SESSION_SUCCESS]({ session }));
		store.dispatch(sessionActions[SESSION_CHECKED_SUCCESS]());
		loadUser().then((user) => {
			store.dispatch(sessionActions[GET_USER_SESSION_SUCCESS]({ user }));
			store.dispatch(sessionActions[USER_CHECKED_SUCCESS]());
		})
			.catch(() => store.dispatch(sessionActions[USER_CHECKED_ERROR]()));
	} catch {
		store.dispatch(sessionActions[SESSION_CHECKED_ERROR]());
		store.dispatch(sessionActions[USER_CHECKED_ERROR]());
		store.dispatch(sessionActions[GET_SESSION_ERROR]());
	}
}

function loadSession(): Promise<void | Error> {
	return new Promise((resolve, reject) => {
		const currentSession = Cookies.get(USER_SESSION)
		if (currentSession) {
			resolve(JSON.parse(currentSession));
		} else {
			reject('Session not found');
		}
	});
}

function loadUser(): Promise<void | Error>  {
	return new Promise((resolve, reject) => {
		const currentUser = Cookies.get(USER_DATA)
		if (currentUser) {
			resolve(JSON.parse(currentUser));
		} else {
			reject('User not found');
		}
	});
}

export function initSessionService(store: any){
	refreshFromLocalStorage(store)
}

export function saveSession(store: any, session: any): Promise<void> {
	return new Promise<void>((resolve) => {
		Cookies.set(USER_SESSION, JSON.stringify(session))
		store.dispatch(sessionActions[GET_SESSION_SUCCESS]({session}));
		resolve()
	});
}

export async function deleteSession(store: any): Promise<void> {
	Cookies.remove(USER_SESSION);
	store.dispatch(sessionActions[GET_SESSION_ERROR]());
}

export function saveUser(store: any, user: any): Promise<void> {
	return new Promise<void>((resolve) => {
		Cookies.set(USER_DATA, JSON.stringify(user))
		store.dispatch(sessionActions[GET_USER_SESSION_SUCCESS]({user}));
		resolve();
	});
}


export async function deleteUser(store: any): Promise<void> {
	Cookies.remove(USER_DATA);
	store.dispatch(sessionActions[GET_USER_SESSION_ERROR]());
}
