import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { ProductCatalogContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import {
  Autocomplete,
  Backdrop, Box, Button, Checkbox, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Tab, Tabs,
  TextField, Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { currencyMask, parseCurrency, maskPhone } from "../../libs/masks"
import { Add } from "@mui/icons-material";
import { useAppDispatch } from "../../app/hooks";
import { instanceTeleclinica } from "../../libs/instanceTeleclinica";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { convertToArray } from "../../libs/convertToArray";

interface Props extends PropsWithChildren {
}

const validation = (value: string, fieldName: string) => {
  switch (fieldName) {
    case "codigoEmpresa":
      return value.length === 0
    case "codigoSubempresa":
      return value.length === 0
    case "produto":
      return value.length === 0
    case "name":
      return value.length < 3
    case "description":
      return value.length < 5
    case "referencePrice":
      return value === "0"
    case "referenceAdditionalPricePerDependent":
      return value === "R$ 0,00"
    default:
      return true
  }
}

export const productColumns = [
  { title: 'Id', field: 'id', hidden: false, filtering: false },
  { title: 'Códigos TopMed', field: 'topMedData', hidden: true, filtering: false },
  { title: 'Nome', field: 'name', filtering: false },
  { title: 'Descrição', field: 'description', filtering: false },
  { title: 'Mínimo de dependentes', field: 'minDependents', filtering: false },
  { title: 'Máximo de dependentes', field: 'maxDependents', filtering: false },
  { title: 'Preço de referência', field: 'referencePrice', filtering: false },
  { title: 'Preço de referência por dependente', field: 'referenceAdditionalPricePerDependent', filtering: false },
  { title: 'Metodos de pagamento', field: 'paymentMethod', filtering: false },
  { title: 'Recorrências', field: 'recurrency', filtering: false },
  { title: 'Status', field: 'status', filtering: false },
  { title: 'Criado em', field: 'createdAt', filtering: false, type: "date", editable: "never" },
  { title: 'Atualizado em', field: 'updatedAt', filtering: false, type: "date", editable: "never" },
]

function Products(props: Props) {
  const appDispatch = useAppDispatch()
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)

  const columns = useRef(productColumns)

  const [payload, setPayload] = useState({
    produto: "45",
    name: "",
    description: "",
    referencePrice: currencyMask("0"),
    referenceAdditionalPricePerDependent: currencyMask("0"),
    minDependents: "3",
    maxDependents: "10",
    paymentMethod: {
      pix: false,
      creditCard: true
    },
    recurrency: {
      monthly: false,
      yearly: false
    },
    status: "active"
  })

  const [isCreateOrUpdate, setIsCreateOrUpdate] = useState(false)
  const [productUpdateTab, setProductUpdateTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const tableRef = useRef(null)
  const [data, setData] = useState([])
  const [hasDependents, SetHasDependents] = useState(false)
  const [isRequestMade, setIsRequestMade] = useState(false)

  const token: string = useAppSelector((state: RootState) => state.sessionReducer.session.signInUserSession.idToken.jwtToken);

  const handleOnSave = useCallback(async () => {
    setIsCreateOrUpdate(true)

    const product: any = {
      topMedData: {
        produto: parseFloat(payload.produto)
      },
      name: payload.name,
      description: payload.description,
      referencePrice: parseCurrency(payload.referencePrice),
      paymentMethod: convertToArray(payload.paymentMethod),
      recurrency: convertToArray(payload.recurrency),
      status: payload.status
    }

    if (hasDependents){
      product.minDependents = parseFloat(payload.minDependents),
      product.maxDependents = parseFloat(payload.maxDependents),
      product.referenceAdditionalPricePerDependent = parseCurrency(payload.referenceAdditionalPricePerDependent)
    }

    console.log(product)
    await instanceTeleclinica.post("/product", product, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(res => {
        setIsRequestMade(!isRequestMade)
        setIsCreateOrUpdate(!isCreateOrUpdate)
      })
      .catch(er => {
        setIsCreateOrUpdate(false)
      })

  }, [payload, isRequestMade, isCreateOrUpdate, hasDependents])

  const handleOnCancel = useCallback(() => {
    setIsCreateOrUpdate(false)
    setProductUpdateTab(0)
  }, [])

  const { pix, creditCard } = payload.paymentMethod;
  const errorPaymentMethod = [pix, creditCard].filter((v) => v).length < 1;

  const { monthly, yearly } = payload.recurrency;
  const errorRecurrency = [monthly, yearly].filter((v) => v).length < 1;

  const validateForm = useCallback(() => {
    let validateIfhasDependents = true
    if (hasDependents) {
      validateIfhasDependents = parseInt(payload.minDependents) <= parseInt(payload.maxDependents) && payload.referenceAdditionalPricePerDependent !== "R$ 0,00"
    }
    return payload.produto !== "" && payload.name.length >= 3 && payload.description.length >= 5
      && payload.referencePrice !== "R$ 0,00" && validateIfhasDependents
      && !errorRecurrency && !errorPaymentMethod

  }, [payload, errorRecurrency, errorPaymentMethod, hasDependents])

  useEffect(() => {
    if (!isRequestMade) {
      setIsLoading(true)
      instanceTeleclinica.get(`/products`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then((res) => {
          setIsLoading(false)
          setData(res.data.products)
          setIsRequestMade(!isRequestMade)
        })
    }
  }, [data, isRequestMade, isLoading])

  return (
    <ProductCatalogContainer maxWidth={false} disableGutters>
      <Box style={{
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: 5,
        alignItems: "center",
        width: "15%", height: 100, minWidth: 200
      }}>
        <Typography fontSize={20}>
          PRODUTOS CADASTRADOS
        </Typography>
      </Box>
      <CustomTable
        title="Catálogo de produtos"
        pageSizeOptions={[10, 50, 100]}
        pageSize={pageSize}
        page={page}
        columns={columns.current}
        getData={data}
        options={{
          filtering: true
        }}
        style={{ margin: "0 2% 0 2%" }}
        isLoading={isLoading}
        actions={[
          {
            icon: () => <Add />,
            tooltip: 'Adicionar',
            isFreeAction: true,
            onClick: () => { setIsCreateOrUpdate(!isCreateOrUpdate) }
          }
        ]}
        onChangePage={(_page: number, _pageSize: number) => {
          setPage(_page)
          setPageSize(_pageSize)
        }}
        standartTableRef={tableRef}
      />
      <Dialog open={isCreateOrUpdate} maxWidth={false}>
        <DialogTitle id={"mainLabel"} sx={{
          textAlign: "center",
          fontSize: 24
        }}>
          Cadastro de novo produto
        </DialogTitle>
        <DialogContent sx={{ width: "95vw", height: "80vh" }}>
          <Box>
            <Tabs value={productUpdateTab} onChange={(e, newValue) => setProductUpdateTab(newValue)} aria-label="basic tabs example">
              <Tab label="Produto" />
            </Tabs>
          </Box>
          <Grid container padding={"10px 0 0 0"}>
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Códigos TopMed:
              </Typography>
            </Grid>
            <Grid xs={3} m={1}>
              <FormControl fullWidth>
                <InputLabel id="produto-select-label-input">Código</InputLabel>
                <Select
                  labelId="produto-select-label"
                  id="produto-select"
                  value={payload.produto}
                  label="Mínimo de dependentes"
                  onChange={(e) => { setPayload({ ...payload, produto: e.target.value }) }}
                >
                  <MenuItem value={"18"}>Sem especialidade (18)</MenuItem>
                  <MenuItem value={"45"}>Com especialidade (45)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Informações do produto:
              </Typography>
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="name" label="Nome do produto" variant="outlined" fullWidth
                value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                error={validation(payload.name, "name")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="description" label="Descrição" variant="outlined" fullWidth
                value={payload.description} onChange={(e) => setPayload({ ...payload, description: e.target.value })}
                error={validation(payload.description, "description")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="referencePrice" label="Preço de referência" variant="outlined" fullWidth
                value={payload.referencePrice} onChange={(e) => setPayload({ ...payload, referencePrice: currencyMask(e.target.value) })}
                error={validation(payload.referencePrice, "referencePrice")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={12}>
              <FormControlLabel control={<Checkbox value={hasDependents} onChange={(e) => SetHasDependents(e.target.checked)} />} label="Dependentes" />
            </Grid>
            {
              hasDependents ?
                <>
                  <Grid xs={3} m={1}>
                    <TextField id="referenceAdditionalPricePerDependent" label="Preço de referência por dependente" variant="outlined" fullWidth
                      value={payload.referenceAdditionalPricePerDependent} onChange={(e) => setPayload({ ...payload, referenceAdditionalPricePerDependent: currencyMask(e.target.value) })}
                      error={validation(payload.referenceAdditionalPricePerDependent, "referenceAdditionalPricePerDependent")} helperText="* Este campo é obrigatório"
                    />
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Mínimo de dependentes</InputLabel>
                      <Select
                        labelId="min-dependents-select-label"
                        id="min-dependents-select"
                        value={payload.minDependents}
                        label="Mínimo de dependentes"
                        onChange={(e) => { setPayload({ ...payload, minDependents: e.target.value }) }}
                      >
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Máximo de dependentes</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payload.maxDependents}
                        label="Mínimo de dependentes"
                        onChange={(e) => { setPayload({ ...payload, maxDependents: e.target.value }) }}
                      >
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <></>
            }
            <Grid xs={4} m={1}>
              <FormControl error={errorPaymentMethod} component="fieldset" variant="standard">
                <FormLabel component="legend">Métodos de pagamento</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={payload.paymentMethod.pix}
                        onChange={(e) => { setPayload({ ...payload, paymentMethod: { ...payload.paymentMethod, pix: e.target.checked } }) }} />
                    }
                    label="Pix"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={payload.paymentMethod.creditCard}
                        onChange={(e) => { setPayload({ ...payload, paymentMethod: { ...payload.paymentMethod, creditCard: e.target.checked } }) }} />
                    }
                    label="Cartão de crédito"
                  />
                </FormGroup>
                <FormHelperText>Minímo de seleção</FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={4} m={1}>
              <FormControl error={errorRecurrency} component="fieldset" variant="standard">
                <FormLabel component="legend">Recorrências</FormLabel>
                <FormGroup >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={payload.recurrency.monthly}
                        onChange={(e) => { setPayload({ ...payload, recurrency: { ...payload.recurrency, monthly: e.target.checked } }) }} />
                    }
                    label="Mensal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={payload.recurrency.yearly}
                        onChange={(e) => { setPayload({ ...payload, recurrency: { ...payload.recurrency, yearly: e.target.checked } }) }} />
                    }
                    label="Anual"
                  />
                </FormGroup>
                <FormHelperText>Minímo de seleção</FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={4} m={1}>
              <FormControl
                fullWidth
              >
                <InputLabel id="input-select">Status</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={payload.status}
                  label="Age"
                  onChange={(e) => setPayload({ ...payload, status: e.target.value })}
                >
                  <MenuItem value={"active"}>Ativo</MenuItem>
                  <MenuItem value={"inactive"}>Inátivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ width: "100%", alignItems: "flex-end" }}>
          <Button id="save" variant="contained" fullWidth sx={{ width: "20%" }}
            disabled={!validateForm()}
            onClick={handleOnSave}
          >
            Salvar
          </Button>
          <Button variant="contained" color="error" fullWidth sx={{ width: "20%" }}
            onClick={handleOnCancel}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </ProductCatalogContainer>
  )
}

export default Products;