export const maskCpf = (text: string) => {
  if (!text) {
    return ""
  }
  if(text.length <= 14){
    return text
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
  }else {
    return text
      .replace(/\D/g, "")
      .replace(/[.]/g, "")
      .replace(/[-]/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/([.]\d{3})(\d)/, "$1/$2")
      .replace(/([/]\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }
}

export const maskPhone = (text: string) => {
  return text
    .replace(/\D/g,"")
    .replace(/(\d{2})(\d)/, "$1 $2")
    .replace(/(\d{2}) (\d{2})(\d)/, "$1 $2 $3")
    .replace(/(\d{9})\d+?$/, "$1")
}

export const maskCep = (text: string) => {
  return text
    .replace(/\D/g,"")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})(\d)/, "$1")
}

export const maskDate = (text: string) => {
  return !text?"":text
    .replace(/\D/g,"")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1")
}

export const maskPhoneNumber = (text: string) => {
  return text
    .replace(/\D/g,"")
    .replace(/(\d)/, "($1")
    .replace(/([(]\d{2})(\d)/, "$1)$2")
    .replace(/([)]\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1")
}

export const maskCustomLink = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s/g,"")
}

export const currencyMask = (value: string) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  )
  return 'R$ ' + result
}

export const maskPercentage = (value: string) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  )
  return result.toString()
}

export const currencyUnMask = (value: string) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  return value
}

export const parseCurrency = (value: string) => {
  return parseFloat(value.replace('R$ ', '').replace(',', '.'));
}

