import { API, Auth } from "aws-amplify";

export interface User {
    id?: number;
    cognitoId?: string;
    name: string;
    type?: string;
    phone: string;
    email: string;
    document: string;
    status?: string;
    searchText?: string;
    createdAt?: string;
    updatedAt?: string;
    groupId?: number | undefined;
}

export async function createUser(user: User){
    const response = await API.post('tex', `/users`, {
        body: user,
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export interface ListQueryStringParameters extends Record<string, string> {
    limit: string;
    offset: string;
    search: string;
    filters: string;
    orderByCollection: string;
}

export interface ListResult {
    limit: number;
    offset: number;
    users: any[];
    totalCount: number;
}

export async function listUser(queryStringParameters: ListQueryStringParameters): Promise<ListResult>{
    const params = new URLSearchParams(queryStringParameters);
    const response = await API.get('tex', `/users?${params}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function readUser(userId: number){
    const response = await API.get('tex', `/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function deleteUser(userId: number){
    const response = await API.del('tex', `/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function updateUser(user: User){
    const response = await API.put('tex', `/users/${user.id}`, ({
        body: user,
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    }));
    return response;
}