import React, { PropsWithChildren } from "react";
import { MockContainer } from "./styles";
import { Typography } from "@mui/material";

interface Props extends PropsWithChildren{
}

function Mock(props: Props){
    return(
        <MockContainer>
            <Typography>Um texto qualquer</Typography>
        </MockContainer>
    )
}

export default Mock;