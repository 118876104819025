import { Navigate, Outlet } from 'react-router-dom';

interface PublicRouteProps {
    authenticated: boolean;
    authenticatedRoute: string;
}

const PublicRoute = ({authenticated, authenticatedRoute}:PublicRouteProps) => {
    return (
        !authenticated ? 
        <Outlet/>
        :
        <Navigate to={authenticatedRoute} replace/>
    );
};

export default PublicRoute;
