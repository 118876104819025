import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CREATE_USER, DELETE_USER, LIST_USER, READ_USER, UPDATE_USER } from "../../app/actionTypes";
import { 
    User,
    createUser as _createUser,
    listUser as _listUser,
    updateUser as _updateUser,
    readUser as _readUser,
    deleteUser as _deleteUser
} from "../../services/user";
import { QueryParameters, ResultQuery } from "../../components/CustomTable";
import { columnsUserManagement } from ".";

export const createUser = createAsyncThunk(
	CREATE_USER,
	async (user: User, thunkAPI) => {
		try {
            const response = await _createUser({
                ...user,
                phone: '+' + user.phone.split(' ').join(''),
                document: user.document.split('.').join('').replace('/', '').replace('-', '')
            })
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const listUser = createAsyncThunk(
	LIST_USER,
	async (queryParameters: QueryParameters, thunkAPI) => {
		try {
            let queryStringParameters = {
                offset: (queryParameters.page*queryParameters.pageSize).toFixed(0),
                limit: (queryParameters.pageSize).toFixed(0),
                search: queryParameters.search || "",
                filters: queryParameters.filters ? JSON.stringify(
                    queryParameters.filters.map((filter: any) => ({
                        column: filter.column.field,
                        operator: filter.operator,
                        value: filter.value
                    }))
                ) : "",
                orderByCollection: queryParameters.orderByCollection ? JSON.stringify(queryParameters.orderByCollection.map((orderBy: any) => ({
                    column: columnsUserManagement[orderBy.orderBy].field,
                    orderDirection: orderBy.orderDirection
                }))) : ""
            }
            const response = await _listUser(queryStringParameters)
            const result: ResultQuery = {
                data: response.users,
                page: Math.round(response.offset/response.limit),
                totalCount: response.totalCount
            }
			return result
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message || error
			})
		}
	}
)

export const readUser = createAsyncThunk(
	READ_USER,
	async (userId: number, thunkAPI) => {
		try {
            const response = await _readUser(userId)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const deleteUser = createAsyncThunk(
	DELETE_USER,
	async (userId: number, thunkAPI) => {
		try {
            const response = await _deleteUser(userId)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const updateUser = createAsyncThunk(
	UPDATE_USER,
	async (user: User, thunkAPI) => {
		try {
            const response = await _updateUser(user)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

const initialState = {
    users: {
        data: [],
        page: 0,
        totalCount: 0
    },
    loading: false,
    lastCreatedUser: null,
    lastUpdatedUser: null,
    user: null,
    error: null
}

const slice = createSlice({
    name: 'groupManagement',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(createUser.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(createUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(createUser.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.lastCreatedUser = payload
        }),
        builder.addCase(listUser.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(listUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(listUser.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.users = payload
        }),
        builder.addCase(readUser.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(readUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(readUser.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.user = payload
        }),
        builder.addCase(deleteUser.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(deleteUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(deleteUser.fulfilled, (state: any) => {
            state.loading = false
        }),
        builder.addCase(updateUser.pending, (state: any) => {
            state.loading = true
        }),
        builder.addCase(updateUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.error = payload
        }),
        builder.addCase(updateUser.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
            state.loading = false
            state.lastUpdatedUser = payload
        })
    }
})

export const actions = slice.actions
export const reducer = slice.reducer