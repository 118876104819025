import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { ProductCatalogContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import {
  Autocomplete,
  Backdrop, Box, Button, Checkbox, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs,
  TextField, Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { currencyMask, parseCurrency, maskPhone, maskPercentage } from "../../libs/masks"
import { Add } from "@mui/icons-material";
import { useAppDispatch } from "../../app/hooks";
import { instanceTeleclinica } from "../../libs/instanceTeleclinica";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { convertToArray } from "../../libs/convertToArray";

interface Props extends PropsWithChildren {
}

const validation = (value: string, fieldName: string) => {
  switch (fieldName) {
    case "subsidiaryId":
      return value.length === 0
    case "productId":
      return value.length === 0
    case "price":
      return value === "R$ 0,00"
    case "additionalPricePerDependent":
      return value === "R$ 0,00"
    case "feeTeleclinicaPix":
      return value === "R$ 0,00"
    case "feeTeleclinicaCreditCard":
      return value === "R$ 0,00"
    case "feeTeleclinicaPixPerDependent":
      return value === "R$ 0,00"
    case "feeTeleclinicaCreditCardPerDependent":
      return value === "R$ 0,00"
    case "feeSubsidiaryPix":
      return value === "R$ 0,00"
    case "feeSubsidiaryCreditCard":
      return value === "R$ 0,00"
    case "feeSubsidiaryPixPerDependent":
      return value === "R$ 0,00"
    case "feeSubsidiaryCreditCardPerDependent":
      return value === "R$ 0,00"
    default:
      return true
  }
}

export const productColumns = [
  { title: 'Id', field: 'id', hidden: false, filtering: false },
  { title: 'Subsidiary Id', field: 'subsidiaryId', hidden: true, filtering: false },
  { title: 'Produto Id', field: 'productId', filtering: false },
  { title: 'Preço', field: 'price', filtering: false },
  { title: 'Adicional por dependente', field: 'additionalPricePerDependent', filtering: false },
  { title: 'Teleclinica Pix', field: 'feeTeleclinicaPix', filtering: false },
  { title: 'Teleclinica Pix Tipo', field: 'feeTeleclinicaPixType', filtering: false },
  { title: 'Teleclinica Credit', field: 'feeTeleclinicaCreditCard', filtering: false },
  { title: 'Teleclinica Credit Tipo', field: 'feeTeleclinicaCreditCardType', filtering: false },
  { title: 'Teleclinica Pix Por Dependente', field: 'feeTeleclinicaPixPerDependent', filtering: false },
  { title: 'Teleclinica Pix Por Dependente Tipo', field: 'feeTeleclinicaPixPerDependentType', filtering: false },
  { title: 'Teleclinica Credit Card Por Dependente', field: 'feeTeleclinicaCreditCardPerDependent', filtering: false },
  { title: 'Teleclinica Credit Card Por Dependente Tipo', field: 'feeTeleclinicaCreditCardPerDependentType', filtering: false },
  { title: 'Teleclinica Credit Card Por Dependente', field: 'feeSubsidiaryPix', filtering: false },
  { title: 'Subsidiary Pix', field: 'feeSubsidiaryPix', filtering: false },
  { title: 'Subsidiary Pix Tipo', field: 'feeSubsidiaryPixType', filtering: false },
  { title: 'Subsidiary Cartão de Credito', field: 'feeSubsidiaryCreditCard', filtering: false },
  { title: 'Subsidiary Cartão de Credito Tipo', field: 'feeSubsidiaryCreditCardType', filtering: false },
  { title: 'Subsidiary Pix Por Dependente', field: 'feeSubsidiaryPixPerDependent', filtering: false },
  { title: 'Subsidiary Pix Por Dependente Tipo', field: 'feeSubsidiaryPixPerDependentType', filtering: false },
  { title: 'Subsidiary Credit Card Por Dependente', field: 'feeSubsidiaryCreditCardPerDependent', filtering: false },
  { title: 'Subsidiary Credit Card Por Dependente Tipo', field: 'feeSubsidiaryCreditCardPerDependentType', filtering: false },
  { title: 'Status', field: 'status', filtering: false },
  { title: 'Criado em', field: 'createdAt', filtering: false, type: "date", editable: "never" },
  { title: 'Atualizado em', field: 'updatedAt', filtering: false, type: "date", editable: "never" },
]

function SubsidiaryProducts(props: Props) {
  const appDispatch = useAppDispatch()
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)

  const columns = useRef(productColumns)

  const [payload, setPayload] = useState({
    subsidiaryId: "",
    productId: "",
    price: currencyMask("0"),
    additionalPricePerDependent: currencyMask("0"),
    feeTeleclinicaPix: maskPercentage("0"),
    feeTeleclinicaPixType: "fixedValue",
    feeTeleclinicaCreditCard: maskPercentage("0"),
    feeTeleclinicaCreditCardType: "fixedValue",
    feeTeleclinicaPixPerDependent: maskPercentage("0"),
    feeTeleclinicaPixPerDependentType: "fixedValue",
    feeTeleclinicaCreditCardPerDependent: maskPercentage("0"),
    feeTeleclinicaCreditCardPerDependentType: "fixedValue",
    feeSubsidiaryPix: maskPercentage("0"),
    feeSubsidiaryPixType: "fixedValue",
    feeSubsidiaryCreditCard: maskPercentage("0"),
    feeSubsidiaryCreditCardType: "fixedValue",
    feeSubsidiaryPixPerDependent: maskPercentage("0"),
    feeSubsidiaryPixPerDependentType: "fixedValue",
    feeSubsidiaryCreditCardPerDependent: maskPercentage("0"),
    feeSubsidiaryCreditCardPerDependentType: "fixedValue",
    status: "active"
  })

  const [isCreateOrUpdate, setIsCreateOrUpdate] = useState(false)
  const [productUpdateTab, setProductUpdateTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const tableRef = useRef(null)
  const [data, setData] = useState([])
  const [hasDependents, SetHasDependents] = useState(false)
  const [isRequestMade, setIsRequestMade] = useState(false)

  const token: string = useAppSelector((state: RootState) => state.sessionReducer.session.signInUserSession.idToken.jwtToken);

  const handleOnSave = useCallback(async () => {
    setIsCreateOrUpdate(true)

    const product: any = {
      subsidiaryId: payload.subsidiaryId,
      productId: payload.productId,
      price: parseCurrency(payload.price),
      additionalPricePerDependent: parseCurrency(payload.additionalPricePerDependent),
      feeTeleclinicaPix: parseCurrency(payload.feeTeleclinicaPix),
      feeTeleclinicaPixType: payload.feeTeleclinicaPixType,
      feeTeleclinicaCreditCard: parseCurrency(payload.feeTeleclinicaCreditCard),
      feeTeleclinicaCreditCardType: payload.feeTeleclinicaCreditCardType,
      feeSubsidiaryPix: parseCurrency(payload.feeSubsidiaryPix),
      feeSubsidiaryPixType: payload.feeSubsidiaryPixType,
      feeSubsidiaryCreditCard: parseCurrency(payload.feeSubsidiaryCreditCard),
      feeSubsidiaryCreditCardType: payload.feeSubsidiaryCreditCardType,
      status: payload.status
    }

    if (hasDependents) {
      product.feeTeleclinicaPixPerDependent = parseCurrency(payload.feeTeleclinicaPixPerDependent),
      product.feeTeleclinicaPixPerDependentType = payload.feeTeleclinicaPixPerDependentType,
      product.feeTeleclinicaCreditCardPerDependent = parseCurrency(payload.feeTeleclinicaCreditCardPerDependent),
      product.feeTeleclinicaCreditCardPerDependentType = payload.feeTeleclinicaCreditCardPerDependentType,
      product.feeSubsidiaryPixPerDependent = parseCurrency(payload.feeSubsidiaryPixPerDependent),
      product.feeSubsidiaryPixPerDependentType = payload.feeSubsidiaryPixPerDependentType,
      product.feeSubsidiaryCreditCardPerDependent = parseCurrency(payload.feeSubsidiaryCreditCardPerDependent),
      product.feeSubsidiaryCreditCardPerDependentType = payload.feeSubsidiaryCreditCardPerDependentType
    }

    console.log(product)
    await instanceTeleclinica.post("/subsidiary-product", product, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(res => {
        setIsRequestMade(!isRequestMade)
        setIsCreateOrUpdate(!isCreateOrUpdate)
      })
      .catch(er => {
        setIsCreateOrUpdate(false)
      })

  }, [payload, isRequestMade, isCreateOrUpdate, hasDependents])

  const handleOnCancel = useCallback(() => {
    setIsCreateOrUpdate(false)
    setProductUpdateTab(0)
  }, [isCreateOrUpdate, productUpdateTab])

  const validateForm = useCallback(() => {
    let validateDependents = true
    if (hasDependents) {
      validateDependents = payload.feeTeleclinicaPixPerDependent !== "" && payload.feeTeleclinicaCreditCardPerDependent !== ""
        && payload.feeSubsidiaryPixPerDependent !== "" && payload.feeSubsidiaryCreditCardPerDependent !== ""
    }
    return payload.subsidiaryId !== "" && payload.productId !== "" && payload.price !== "R$ 0,00"
      && payload.feeTeleclinicaPix !== "0,00" && payload.feeTeleclinicaCreditCard !== "0,00"
      && payload.feeSubsidiaryPix !== "0,00" && payload.feeSubsidiaryCreditCard !== "0,00"
      && validateDependents
  }, [payload, hasDependents])

  useEffect(() => {
    if (!isRequestMade) {
      setIsLoading(true)
      instanceTeleclinica.get(`/subsidiary-products`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then((res) => {
          setIsLoading(false)
          setData(res.data.products)
          setIsRequestMade(!isRequestMade)
        })
    }
  }, [data, isRequestMade, isLoading])

  return (
    <ProductCatalogContainer maxWidth={false} disableGutters>
      <Box style={{
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: 5,
        alignItems: "center",
        width: "15%", height: 100, minWidth: 200
      }}>
        <Typography fontSize={20}>
          PRODUTOS VINCULADOS A UMA SUBSIDIARY
        </Typography>
      </Box>
      <CustomTable
        title="Catálogo de produtos"
        pageSizeOptions={[10, 50, 100]}
        pageSize={pageSize}
        page={page}
        columns={columns.current}
        getData={data}
        options={{
          filtering: true
        }}
        style={{ margin: "0 2% 0 2%" }}
        isLoading={isLoading}
        actions={[
          {
            icon: () => <Add />,
            tooltip: 'Adicionar',
            isFreeAction: true,
            onClick: () => { setIsCreateOrUpdate(!isCreateOrUpdate) }
          }
        ]}
        onChangePage={(_page: number, _pageSize: number) => {
          setPage(_page)
          setPageSize(_pageSize)
        }}
        standartTableRef={tableRef}
      />
      <Dialog open={isCreateOrUpdate} maxWidth={false}>
        <DialogTitle id={"mainLabel"} sx={{
          textAlign: "center",
          fontSize: 24
        }}>
          Vincular um produto a uma Subsidiary
        </DialogTitle>
        <DialogContent sx={{ width: "95vw", height: "80vh" }}>
          <Box>
            <Tabs value={productUpdateTab} onChange={(e, newValue) => setProductUpdateTab(newValue)} aria-label="basic tabs example">
              <Tab label="Produto" />
            </Tabs>
          </Box>
          <Grid container padding={"10px 0 0 0"}>
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Informações básicas:
              </Typography>
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="subsidiaryId" label="Id da Subsidiary" variant="outlined" fullWidth
                value={payload.subsidiaryId} onChange={(e) => setPayload({ ...payload, subsidiaryId: e.target.value })}
                error={validation(payload.subsidiaryId, "subsidiaryId")} helperText="* Este campo é obrigatório" type="number"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="productId" label="Id do Produto" variant="outlined" fullWidth
                value={payload.productId} onChange={(e) => setPayload({ ...payload, productId: e.target.value })}
                error={validation(payload.productId, "productId")} helperText="* Este campo é obrigatório" type="number"
              />
            </Grid>
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Informações do produto:
              </Typography>
            </Grid>
            <Grid xs={12}>
              <FormControlLabel control={<Checkbox value={hasDependents} onChange={(e) => SetHasDependents(e.target.checked)} />} label="Dependentes" />
            </Grid>
            <Grid xs={3} m={1}>
              <TextField id="price" label="Preço" variant="outlined" fullWidth
                value={payload.price} onChange={(e) => setPayload({ ...payload, price: currencyMask(e.target.value) })}
                error={validation(payload.price, "price")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            {
              hasDependents ?
                <Grid xs={3} m={1}>
                  <TextField id="additionalPricePerDependent" label="Adicional por dependente" variant="outlined" fullWidth
                    value={payload.additionalPricePerDependent} onChange={(e) => setPayload({ ...payload, additionalPricePerDependent: currencyMask(e.target.value) })}
                    error={validation(payload.additionalPricePerDependent, "additionalPricePerDependent")} helperText="* Este campo é obrigatório"
                  />
                </Grid>
                : <></>
            }
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Taxas Teleclinica:
              </Typography>
            </Grid>
            <Grid xs={2} m={1}>
              <TextField id="feeTeleclinicaPix" label="Pix" variant="outlined" fullWidth
                value={payload.feeTeleclinicaPix} 
                InputProps={{
                  startAdornment: <InputAdornment position="start">{payload.feeTeleclinicaPixType === "fixedValue"? "R$": "%"}</InputAdornment>,
                }}
                onChange={(e) => setPayload({ ...payload, feeTeleclinicaPix: maskPercentage(e.target.value) })}
                error={validation(payload.feeTeleclinicaPix, "feeTeleclinicaPix")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Pix</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.feeTeleclinicaPixType}
                  label="taxa"
                  onChange={(e) => { setPayload({ ...payload, feeTeleclinicaPixType: e.target.value }) }}
                >
                  <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                  <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={2} m={1}>
              <TextField id="feeTeleclinicaCreditCard" label="Cartão de Crédito" variant="outlined" fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{payload.feeTeleclinicaCreditCardType === "fixedValue"? "R$": "%"}</InputAdornment>,
                }}
                value={payload.feeTeleclinicaCreditCard} onChange={(e) => setPayload({ ...payload, feeTeleclinicaCreditCard: maskPercentage(e.target.value) })}
                error={validation(payload.feeTeleclinicaCreditCard, "feeTeleclinicaCreditCard")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cartão de Crédito</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.feeTeleclinicaCreditCardType}
                  label="taxa"
                  onChange={(e) => { setPayload({ ...payload, feeTeleclinicaCreditCardType: e.target.value }) }}
                >
                  <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                  <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
              hasDependents ?
                <>
                  <Grid xs={2} m={1}>
                    <TextField id="feeTeleclinicaPixPerDependent" label="Pix por dependente" variant="outlined" fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{payload.feeTeleclinicaPixPerDependentType === "fixedValue"? "R$": "%"}</InputAdornment>,
                      }}
                      value={payload.feeTeleclinicaPixPerDependent} onChange={(e) => setPayload({ ...payload, feeTeleclinicaPixPerDependent: maskPercentage(e.target.value)})}
                      error={validation(payload.feeTeleclinicaPixPerDependent, "feeTeleclinicaPixPerDependent")} helperText="* Este campo é obrigatório"
                    />
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Pix por dependente</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payload.feeTeleclinicaPixPerDependentType}
                        label="taxa"
                        onChange={(e) => { setPayload({ ...payload, feeTeleclinicaPixPerDependentType: e.target.value }) }}
                      >
                        <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                        <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={2} m={1}>
                    <TextField id="feeTeleclinicaCreditCardPerDependent" label="Cartão de crédito por dependente" variant="outlined" fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{payload.feeTeleclinicaCreditCardPerDependentType === "fixedValue"? "R$": "%"}</InputAdornment>,
                      }}
                      value={payload.feeTeleclinicaCreditCardPerDependent} onChange={(e) => setPayload({ ...payload, feeTeleclinicaCreditCardPerDependent: maskPercentage(e.target.value) })}
                      error={validation(payload.feeTeleclinicaCreditCardPerDependent, "feeTeleclinicaCreditCardPerDependent")} helperText="* Este campo é obrigatório"
                    />
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Cartão de Crédito por dependente</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payload.feeTeleclinicaCreditCardPerDependentType}
                        label="taxa"
                        onChange={(e) => { setPayload({ ...payload, feeTeleclinicaCreditCardPerDependentType: e.target.value }) }}
                      >
                        <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                        <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <></>
            }
            <Grid xs={12} sx={{ height: 40 }}>
              <Typography id="billingAddressLabel" sx={{
                textAlign: "left",
                fontSize: 18,
                margin: 2
              }}>
                Taxas Subsidiary:
              </Typography>
            </Grid>
            <Grid xs={2} m={1}>
              <TextField id="feeSubsidiaryPix" label="Pix" variant="outlined" fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{payload.feeSubsidiaryPixType === "fixedValue"? "R$": "%"}</InputAdornment>,
                }}
                value={payload.feeSubsidiaryPix} onChange={(e) => setPayload({ ...payload, feeSubsidiaryPix: maskPercentage(e.target.value) })}
                error={validation(payload.feeSubsidiaryPix, "feeSubsidiaryPix")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Pix</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.feeSubsidiaryPixType}
                  label="taxa"
                  onChange={(e) => { setPayload({ ...payload, feeSubsidiaryPixType: e.target.value }) }}
                >
                  <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                  <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={2} m={1}>
              <TextField id="feeSubsidiaryCreditCard" label="Cartão de Crédito" variant="outlined" fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{payload.feeSubsidiaryCreditCardType === "fixedValue"? "R$": "%"}</InputAdornment>,
                }}
                value={payload.feeSubsidiaryCreditCard} onChange={(e) => setPayload({ ...payload, feeSubsidiaryCreditCard: maskPercentage(e.target.value) })}
                error={validation(payload.feeSubsidiaryCreditCard, "feeSubsidiaryCreditCard")} helperText="* Este campo é obrigatório"
              />
            </Grid>
            <Grid xs={3} m={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cartão de Crédito</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.feeSubsidiaryCreditCardType}
                  label="taxa"
                  onChange={(e) => { setPayload({ ...payload, feeSubsidiaryCreditCardType: e.target.value }) }}
                >
                  <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                  <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
              hasDependents ?
                <>
                  <Grid xs={2} m={1}>
                    <TextField id="feeSubsidiaryPixPerDependent" label="Pix por dependente" variant="outlined" fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{payload.feeSubsidiaryPixPerDependentType === "fixedValue"? "R$": "%"}</InputAdornment>,
                      }}
                      value={payload.feeSubsidiaryPixPerDependent} onChange={(e) => setPayload({ ...payload, feeSubsidiaryPixPerDependent: maskPercentage(e.target.value) })}
                      error={validation(payload.feeSubsidiaryPixPerDependent, "feeSubsidiaryPixPerDependent")} helperText="* Este campo é obrigatório"
                    />
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Pix por dependente</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payload.feeSubsidiaryPixPerDependentType}
                        label="taxa"
                        onChange={(e) => { setPayload({ ...payload, feeSubsidiaryPixPerDependentType: e.target.value }) }}
                      >
                        <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                        <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={2} m={1}>
                    <TextField id="feeSubsidiaryCreditCardPerDependent" label="Cartão de Crédito por dependente" variant="outlined" fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{payload.feeSubsidiaryCreditCardPerDependentType === "fixedValue"? "R$": "%"}</InputAdornment>,
                      }}
                      value={payload.feeSubsidiaryCreditCardPerDependent} onChange={(e) => setPayload({ ...payload, feeSubsidiaryCreditCardPerDependent: maskPercentage(e.target.value) })}
                      error={validation(payload.feeSubsidiaryCreditCardPerDependent, "feeSubsidiaryCreditCardPerDependent")} helperText="* Este campo é obrigatório"
                    />
                  </Grid>
                  <Grid xs={3} m={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Cartão de Crédito por dependente</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payload.feeSubsidiaryCreditCardPerDependentType}
                        label="taxa"
                        onChange={(e) => { setPayload({ ...payload, feeSubsidiaryCreditCardPerDependentType: e.target.value }) }}
                      >
                        <MenuItem value={"fixedValue"}>Valor Real (R$)</MenuItem>
                        <MenuItem value={"percentualValue"}>Valor Percentual (%)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <></>
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{ width: "100%", alignItems: "flex-end" }}>
          <Button id="save" variant="contained" fullWidth sx={{ width: "20%" }}
            disabled={!validateForm()}
            onClick={handleOnSave}
          >
            Salvar
          </Button>
          <Button variant="contained" color="error" fullWidth sx={{ width: "20%" }}
            onClick={handleOnCancel}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </ProductCatalogContainer>
  )
}

export default SubsidiaryProducts;