import React, { PropsWithChildren } from "react";
import { ProfileContainer } from "./styles";

interface Props extends PropsWithChildren{
}

function Profile(props: Props){
    return(
        <ProfileContainer></ProfileContainer>
    )
}

export default Profile;