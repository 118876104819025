import React, { PropsWithChildren, useEffect, useState } from "react";
import { SignInContainer } from "./styles";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useTheme } from "@mui/material";
import { LoadingButton as Button } from "@mui/lab";
import logo from '../../assets/logo.png';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeAttributesUser, forgotPasswordRequest, resetPasswordWhenForgot, signInActions, signInUser } from "./slice";
import { RootState } from "../../app/store";
import { SAVE_USERNAME_PASSWORD, TOGGLE_IS_LOADING, TOGGLE_REMEMBER_PASSWORD } from "../../app/actionTypes";
import { splashActions } from "../Splash/slice";

interface Props extends PropsWithChildren{
}

function SignInForm(props: any){
    const theme = useTheme()
    const appDispatch = useAppDispatch()
    const storedUsername:string = useAppSelector((state: RootState) => state.signInReducer?.storedUsername);
	const storedPassword:string = useAppSelector((state: RootState) => state.signInReducer?.storedPassword);
    const [payload, setPayload] = useState({
        email: storedUsername || "",
        password: storedPassword || ""
    })
    const [showPassword, setShowPassword] = useState(false)
    const isLoading: boolean = useAppSelector((state: RootState) => state.signInReducer.loading)
    const signInError: any = useAppSelector((state: RootState) => state.signInReducer.signInError)
    const rememberPassword:boolean = useAppSelector((state: RootState) => state.signInReducer?.rememberPassword);
	const toggleRememberPassword = () => appDispatch(signInActions[TOGGLE_REMEMBER_PASSWORD]())
    useEffect(() => {
		if(rememberPassword){
			appDispatch(signInActions[SAVE_USERNAME_PASSWORD]({username: payload.email, password: payload.password}))
		}
	}, [payload, rememberPassword])

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: 400,
            width: 400
        }}>
            <Typography textAlign={"left"} sx={{
                width: "100%",
                fontWeight: "bold",
                fontSize: 20
            }}>
                Autenticação:
            </Typography>
            {signInError &&
                <Typography textAlign={"left"} sx={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 12,
                    color: theme.palette.error.main
                }}>
                    {signInError.code === "UserNotFoundException" || signInError.code === "NotAuthorizedException"? "Nome de usuário ou senha inválidos" : "Erro inesperado, entre em contato com o suporte"}
                </Typography>
            }
            <TextField 
                id="sign-in-email" label="E-mail" variant="outlined" sx={{minWidth: "90%"}}
                value={payload.email}
                onChange={(e: any) => setPayload({...payload, email: e.target.value})}
            />
            <FormControl id="sign-in-password" variant="outlined" sx={{minWidth: "90%"}}>
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="mudar visibilidade da senha"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }
                    label="Senha"
                    value={payload.password}
                    onChange={(e: any) => setPayload({...payload, password: e.target.value})}
                />
            </FormControl>
            <FormGroup sx={{width: "90%"}}>
                <FormControlLabel control={<Checkbox onChange={(event) => {
                    if (rememberPassword !== event.target.checked) {
                        toggleRememberPassword()
                    }
                }} checked={rememberPassword}/>} label="Lembrar senha"  />
            </FormGroup>
            <Button id="sign-in-button" variant="contained" sx={{width: "90%"}}
                onClick={() => props.onClickSubmit(payload)}
                loading={isLoading}
            >
                Entrar
            </Button>
            <Box sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "90%"
            }}>
                <Button id="forgot-password-button" variant="text"
                    onClick={() => {props.onClickForgotPassword()}}
                >Esqueci a senha</Button>
            </Box>
        </Box>
    )
}

function ForgotPasswordForm(props: any){
    const isLoading: boolean = useAppSelector((state: RootState) => state.signInReducer.loading)
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: 300,
            width: 400
        }}>
            <Typography textAlign={"left"} sx={{
                width: "100%",
                fontWeight: "bold",
                fontSize: 20
            }}>
                Escreva o email da sua conta:
            </Typography>
            <TextField id="forgot-password-email" label="E-mail" variant="outlined" sx={{minWidth: "90%"}}/>
            <Button id="forgot-password-button" variant="contained" sx={{width: "90%"}}>Redefinir senha</Button>
            <Box sx={{position: "absolute", top: "30%", left: "50%"}}>
                <Button id="forgot-password-go-back-button" variant="text"
                    onClick={() => {props.onClickGoBack()}} startIcon={<ArrowCircleLeftIcon fontSize="large"/>}
                    loading={isLoading}
                >Voltar</Button>
            </Box>
        </Box>
    )
}

function PasswordResetForm(props: any){
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const isLoading: boolean = useAppSelector((state: RootState) => state.signInReducer.loading)
    const [payload, setPayload] = useState({
        code: "",
        newPassword: "",
        repassword: ""
    })
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: 400,
            width: 400
        }}>
            <Typography textAlign={"left"} sx={{
                width: "100%",
                fontWeight: "bold",
                fontSize: 20
            }}>
                Um código de redefinição foi enviado para o seu e-mail:
            </Typography>
            <TextField
                id="password-reset-code" label="Código"
                variant="outlined" sx={{minWidth: "90%"}}
                onChange={(e:any) => setPayload({...payload, code: e.target.value})}
            />
            <FormControl id="password-reset-password" variant="outlined" sx={{minWidth: "90%"}}>
                <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="mudar visibilidade da senha"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }
                    label="Nova senha"
                    onChange={(e:any) => setPayload({...payload, newPassword: e.target.value})}
                />
            </FormControl>
            <FormControl id="password-reset-repassword" variant="outlined" sx={{minWidth: "90%"}}>
                <InputLabel htmlFor="outlined-adornment-password">Confirme a nova senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showRePassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="mudar visibilidade da senha"
                            onClick={() => setShowRePassword(!showRePassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }
                    label="Confirme a nova senha"
                    onChange={(e:any) => setPayload({...payload, repassword: e.target.value})}
                />
            </FormControl>
            <Button id="password-reset-button" variant="contained" sx={{width: "90%"}}
                onClick={() => props.onClickSubmit(payload)}
                disabled={payload.newPassword !== payload.repassword}
                loading={isLoading}
            >Redefinir Senha</Button>
            <Box>
                <Button id="password-reset-go-back-button" variant="text" onClick={() => {props.onClickGoBack()}} startIcon={<ArrowCircleLeftIcon />}>Voltar</Button>
            </Box>
        </Box>
    )
}

function ChangeAttributeForm(props: any){
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const incompleteUser: any = useAppSelector((state: RootState) => state.signInReducer.incompleteUser)
    const isLoading: boolean = useAppSelector((state: RootState) => state.signInReducer.loading)
    const [payload, setPayload] = useState({
        newPassword: "",
        name: incompleteUser.name,
        document: incompleteUser.document,
        repassword: ""
    })
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: 550,
            width: 400
        }}>
            <Typography textAlign={"left"} sx={{
                width: "100%",
                fontWeight: "bold",
                fontSize: 20
            }}>
                Atualize a sua senha:
            </Typography>
            <FormControl id="change-attributes-password" variant="outlined" sx={{minWidth: "90%"}}>
                <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="mudar visibilidade da senha"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }
                    label="Nova senha"
                    onChange={(e:any) => setPayload({...payload, newPassword: e.target.value})}
                    value={payload.newPassword}
                />
            </FormControl>
            <FormControl id="change-attributes-repassword" variant="outlined" sx={{minWidth: "90%"}}>
                <InputLabel htmlFor="outlined-adornment-password">Confirme a nova senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showRePassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="mudar visibilidade da senha"
                            onClick={() => setShowRePassword(!showRePassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }
                    label="Confirme a nova senha"
                    onChange={(e:any) => setPayload({...payload, repassword: e.target.value})}
                    value={payload.repassword}
                />
            </FormControl>
            <TextField
                id="change-attributes-name" label="Nome"
                variant="outlined" sx={{minWidth: "90%"}}
                onChange={(e:any) => setPayload({...payload, name: e.target.value})}
                value={payload.name}
            />
            <TextField
                id="change-attributes-document" label="CPF/CNPJ"
                variant="outlined" sx={{minWidth: "90%"}}
                onChange={(e:any) => setPayload({...payload, document: e.target.value})}
                value={payload.document}
            />
            <Button id="password-reset-button" variant="contained" sx={{width: "90%"}}
                onClick={() => props.onClickSubmit(payload)}
                disabled={payload.newPassword !== payload.repassword}
                loading={isLoading}
            >Atualizar</Button>
            <Box position={"absolute"} top={150} left={500}>
                <Button id="password-reset-go-back-button" variant="text" onClick={() => {props.onClickGoBack()}} startIcon={<ArrowCircleLeftIcon />}>Voltar</Button>
            </Box>
        </Box>
    )
}

function SignIn(props: Props){
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [isPasswordReset, setIsPasswordReset] = useState(false)
    const [isChangeAttributesRequired, setIsChangeAttributesRequired] = useState(false)
    const theme = useTheme();
    const appDispatch = useAppDispatch();
    const [email, setEmail] = useState("");
    const cognitoChallengeUser: any = useAppSelector((state: RootState) => state.signInReducer.cognitoChallengeUser)

    return(
        <SignInContainer maxWidth={false} disableGutters>
            <Grid container flexDirection={"row"} >
                <Grid xs={4}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: theme.palette.primary.light,
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh"
                    }}>
                        <Box padding={"20px 100px 20px 100px"}>
                            <img src={"https://i.ibb.co/sQZ9H9T/TELECLINICA-PNG.png"} alt="Logo" width="100%" style={{minWidth: 200}}/>
                        </Box>
                        <Box>
                            <Typography 
                                textAlign={"center"}
                                color={theme.palette.secondary.main}
                                fontWeight={"bold"}
                                fontSize={32}
                            >
                                Bem vindo a Teleclinica!
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={8}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        height: "100%",
                        width: "100%",
                    }}>
                        {!isPasswordReset && !isForgotPassword && !isChangeAttributesRequired ?
                            <SignInForm
                                onClickForgotPassword={() => setIsForgotPassword(true)}
                                onClickSubmit={
                                    async ({email, password}: any) => {
                                        try {
                                            const response = await appDispatch(signInUser({username: email, password})).unwrap()
                                            if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
                                                setIsChangeAttributesRequired(true)
                                            }
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }
                                }
                            />
                            :
                            isForgotPassword ?
                                <ForgotPasswordForm
                                    onClickGoBack={() => setIsForgotPassword(false)}
                                    onClickSubmit={
                                        async (email: string) => {
                                            setEmail(email)
                                            await appDispatch(forgotPasswordRequest(email)).unwrap()
                                            setIsForgotPassword(false)
                                            setIsPasswordReset(true)
                                        }
                                    }
                                />
                                : 
                                isPasswordReset ? 
                                    <PasswordResetForm
                                        onClickGoBack={() => {
                                            setIsForgotPassword(false)
                                            setIsPasswordReset(false)
                                        }}
                                        onClickSubmit={
                                            async ({code, newPassord}: any) => {
                                                await appDispatch(resetPasswordWhenForgot({username: email, code, newPassord})).unwrap()
                                                setIsForgotPassword(false)
                                                setIsPasswordReset(false)
                                            }
                                        }
                                    />
                                    :
                                    <ChangeAttributeForm
                                        onClickGoBack={() => {
                                            setIsForgotPassword(false)
                                            setIsPasswordReset(false)
                                            setIsChangeAttributesRequired(false)
                                        }}
                                        onClickSubmit={
                                            async ({newPassword, email, name, phone, document}: any) => {
                                                await appDispatch(changeAttributesUser({cognitoChallengeUser, newPassword, email, name, phone, document})).unwrap()
                                            }
                                        }
                                    />
                        }
                    </Box>
                </Grid>
            </Grid>
        </SignInContainer>
    )
}

export default SignIn;