import { API, Auth } from "aws-amplify";

export interface Log {
    id?: number;
    cognitoId?: number | null;
    event: any;
    response: any;
    statusCode: number;
    createdAt?: string;
}

export interface ListQueryStringParameters extends Record<string, string> {
    limit: string;
    offset: string;
    search: string;
    filters: string;
    orderByCollection: string;
}

export interface ListResult {
    limit: number;
    offset: number;
    totalCount: number;
    logs: any[];
}

export async function listLogs(queryStringParameters: ListQueryStringParameters): Promise<ListResult>{
    const params = new URLSearchParams(queryStringParameters);
    const response = await API.get('tex', `/logs?${params}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

