import { SplashContainer, LogoImage } from './styles';
import logo from '../../assets/logo.png';
import { Box, LinearProgress, Typography, useTheme } from '@mui/material';

export default function Splash(): JSX.Element {
  return(
    <SplashContainer disableGutters maxWidth={false} sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Box width={300} component={'div'}>
        <LogoImage src={logo}/>
      </Box>
      <Box width={400} component={'div'}>
        <Typography textAlign={"center"} fontSize={32} color={"secondary"}>Carregando</Typography>
        <LinearProgress color="primary"/>
      </Box>
    </SplashContainer>
  );
}


