import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	COMPLETE_NEW_PASSWORD,
    FORGOT_PASSWORD,
	RESET_PASSWORD,
	SAVE_USERNAME_PASSWORD,
	SIGN_IN,
	SIGN_OUT,
	TOGGLE_REMEMBER_PASSWORD,
} from '../../app/actionTypes';
import { deleteSession, deleteUser, saveSession } from '../../app/sessionManager';
import { store } from '../../app/store';
import { completeNewPassword, forgotPassword, forgotPasswordSubmit, signIn, signOut } from '../../services/auth';

const initialState = {
	rememberPassword: false,
	storedUsername: "",
	storedPassword: "",
	firstAccessError: null,
	requestCodeError: null,
	validateCodeError: null,
	loading: false,
	validateCode: null,
	signInError: null,
	user: null,
	isNewPasswordRequired: false,
	incompleteUser: null,
	cognitoChallengeUser: null,
}

export const signInUser = createAsyncThunk(
	SIGN_IN,
	async ({ username, password }: any, thunkAPI) => {
		try {
			const response = await signIn({ username, password })
			if (response.challengeName !== "NEW_PASSWORD_REQUIRED") {
				await saveSession(store, response)
			}
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue({
				code: error.code,
				message: error.message
			})
		}
	}
)

export const signOutUser = createAsyncThunk(
	SIGN_OUT,
	async (_, thunkAPI) => {
		try {
			await signOut()
			await deleteSession(store)
			await deleteUser(store)
			return true
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const forgotPasswordRequest = createAsyncThunk(
	FORGOT_PASSWORD,
	async (username: string, thunkAPI) => {
		try {
			await forgotPassword({username})
			return true
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const resetPasswordWhenForgot = createAsyncThunk(
	RESET_PASSWORD,
	async ({username, code, newPassword}: any, thunkAPI) => {
		try {
			await forgotPasswordSubmit({username, code, newPassword})
			return true
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const changeAttributesUser = createAsyncThunk(
	COMPLETE_NEW_PASSWORD,
	async ({ cognitoChallengeUser, newPassword, email, name, phone, document }: any, thunkAPI) => {
		try {
			const response = await completeNewPassword({ user: cognitoChallengeUser, newPassword, name, document})
			await saveSession(store, response.session)
			return response
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

const signInSlice = createSlice({
	name: 'signIn',
	initialState,
	reducers: {
		[TOGGLE_REMEMBER_PASSWORD]: (state) => {
			if(state.rememberPassword){
				state.storedUsername = ""
				state.storedPassword = ""
			}
			state.rememberPassword = !state.rememberPassword
		},
		[SAVE_USERNAME_PASSWORD]: (
			state,
			{
				payload: { username, password }
			}: PayloadAction<{ username: string, password: string, }>
		) => {
			state.storedUsername = username
			state.storedPassword = password
		},
	},
	extraReducers: (builder) => {
		builder.addCase(signInUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
			state.loading = false
			state.signInError = payload
		}),
		builder.addCase(signInUser.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
			if (payload.challengeName === "NEW_PASSWORD_REQUIRED") {
				state.isNewPasswordRequired = true
				state.incompleteUser = {
					name: payload.challengeParam.userAttributes.name,
					email: payload.challengeParam.userAttributes.email,
					phone: payload.challengeParam.userAttributes.phone_number,
					document: payload.challengeParam.userAttributes.preferred_username
				}
				state.cognitoChallengeUser = payload
			} else {
				state.isNewPasswordRequired = false
				state.incompleteUser = null
			}
			state.loading = false
		}),
		builder.addCase(signInUser.pending, (state: any) => {
			state.loading = true
			state.signInError = null
		}),
		builder.addCase(forgotPasswordRequest.rejected, (state: any, {payload}: PayloadAction<any>) => {
			state.loading = false
			state.forgotPasswordError = payload
		}),
		builder.addCase(forgotPasswordRequest.fulfilled, (state: any) => {
			state.loading = false
			state.forgotPasswordError = null
		}),
		builder.addCase(forgotPasswordRequest.pending, (state: any) => {
			state.loading = true
			state.forgotPasswordError = null
		}),
		builder.addCase(resetPasswordWhenForgot.rejected, (state: any, {payload}: PayloadAction<any>) => {
			state.loading = false
			state.resetPasswordError = payload
		}),
		builder.addCase(resetPasswordWhenForgot.fulfilled, (state: any) => {
			state.loading = false
			state.resetPasswordError = null
		}),
		builder.addCase(resetPasswordWhenForgot.pending, (state: any) => {
			state.loading = true
			state.resetPasswordError = null
		}),
		builder.addCase(changeAttributesUser.rejected, (state: any, {payload}: PayloadAction<any>) => {
			state.loading = false
			state.changeAttributesError = payload
		}),
		builder.addCase(changeAttributesUser.fulfilled, (state: any) => {
			state.loading = false
			state.changeAttributesError = null
		}),
		builder.addCase(changeAttributesUser.pending, (state: any) => {
			state.loading = true
			state.changeAttributesError = null
		})
	}
})

export const signInActions = signInSlice.actions;
export default signInSlice.reducer;