import { Auth, API } from 'aws-amplify';
import moment from 'moment-timezone';

interface CognitoUser {
    username: string;
    password: string;
}
  
export async function signIn({ username, password }: CognitoUser){
    const response = await Auth.signIn(username.toLowerCase().trim(), password);
    return response;
};

interface CognitoSignUpUser {
    username: string;
    password: string;
    email: string;
    name: string;
}

export async function signOut() {
    await Auth.signOut();
}

interface CognitoChangePassword {
    oldPassword: string;
    newPassword: string;
}

export async function changePassword({oldPassword, newPassword}: CognitoChangePassword){
    const user = await Auth.currentAuthenticatedUser()
    const data = await Auth.changePassword(user, oldPassword, newPassword);
    return data
}

interface ForgotPassword {
    username: string;
}

export async function forgotPassword({username}: ForgotPassword){
    const data = Auth.forgotPassword(username.toLocaleLowerCase().trim());
    return data;
}

interface ForgotPasswordSubmit {
    username: string;
    code: string;
    newPassword: string;
}

export async function forgotPasswordSubmit({username, code, newPassword}: ForgotPasswordSubmit){
    const data = Auth.forgotPasswordSubmit(username.toLocaleLowerCase().trim(), code, newPassword)
    return data;
}

export async function usernameExists(username: string){
    const {usernameExists} = await API.get('auth', `usernameExists/${encodeURIComponent(username.toLocaleLowerCase().trim())}`,{});
    return usernameExists;
}

export async function adminCreateUser({username, password, email, name}: CognitoSignUpUser){
    const { user } = await Auth.signUp({
        username: username.toLowerCase().trim(),
        password,
        attributes: {
            email: email.toLowerCase().trim(),
            name: name.trim(),
            "custom:createdAt": moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss [GMT]Z')
        },
    });
    return user;
}

interface CompleteNewPassword {
    user: any;
    newPassword: string;
    name: string;
    document: string;
}

export async function completeNewPassword({user, newPassword, name, document}: CompleteNewPassword){
    const response = await Auth.completeNewPassword(
        user,
        newPassword,
        {
            name: name.trim(),
            preferred_username: document
        }
    );
    return response
}