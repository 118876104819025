import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import { Persistor } from "redux-persist/es/types";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './rootReducer';
import { initSessionService } from './sessionManager';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["splashReducer"]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
})

initSessionService(store)
export const persistor:Persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
