/**
 * ROOT
 */

export const GET_SESSION_SUCCESS = 'ROOT/GET_SESSION_SUCCESS'
export const GET_SESSION_ERROR = 'ROOT/GET_SESSION_ERROR'
export const SESSION_CHECKED_SUCCESS = 'ROOT/SESSION_CHECKED_SUCCESS'
export const SESSION_CHECKED_ERROR = 'ROOT/SESSION_CHECKED_ERROR'
export const GET_USER_SESSION_SUCCESS = 'ROOT/GET_USER_SESSION_SUCCESS'
export const GET_USER_SESSION_ERROR = 'ROOT/GET_USER_SESSION_ERROR'
export const USER_CHECKED_SUCCESS = 'ROOT/USER_CHECKED_SUCCESS'
export const USER_CHECKED_ERROR = 'ROOT/USER_CHECKED_ERROR'
export const CLEAR_SESSION = 'ROOT/CLEAR_SESSION'
