import React, { PropsWithChildren, useRef, useState } from "react";
import { LogsContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { listLogs } from "./slice";

interface Props extends PropsWithChildren{
}

export const logColumns = [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'Chave', field: 'event.routeKey' },
    { title: 'Message', field: 'response.message' },
    { title: 'Status', field: 'statusCode' },
    { title: 'Criado em', field: 'createdAt', type: "date" },
]

function Logs(props: Props){
    const appDispatch = useAppDispatch()
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)

    const columns = useRef(logColumns)

    return(
        <LogsContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "15%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    Log de Alterações
                </Typography>
            </Box>
            <CustomTable
                title="Logs"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={async (query: any) => await appDispatch(listLogs(query)).unwrap()}
                options={{
                    filtering: false
                }}
                style={{margin: "0 2% 0 2%"}}
                onChangePage={(_page: number, _pageSize: number) => {
                    setPage(_page)
                    setPageSize(_pageSize)
                }}
            />
        </LogsContainer>
    )
}

export default Logs;