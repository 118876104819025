import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { CustomersContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { 
    Backdrop, Box, Button, Checkbox, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Tab, Tabs,
    TextField, Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { maskCpf, maskPhone } from "../../libs/masks"
import { Add } from "@mui/icons-material";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { instanceTeleclinica } from "../../libs/instanceTeleclinica";
interface Props extends PropsWithChildren{
}

const validation = (value: string, fieldName: string) => {
    switch (fieldName) {
        case "name":
            return value.length < 3 || !value.includes(' ')
        case "email":
            return value.length < 3 || !value.includes('@')
        case "document":
            return value.length !== 14 && value.length !== 18
        case "mainPhone":
            return value.length < 14
        case "street":
            return value.length < 3
        case "number":
            return !value.length
        case "state":
            return value.length < 2
        case "city":
            return value.length < 3
        case "complement":
            return false
        case "neighborhood":
            return value.length < 3
        case "postalCode":
            return value.length < 8
        default:
            return true
    }
}
function Customers(props: Props){
    const [data, setData] = useState([])
    console.log(data)
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)
    const columns = useRef([
        { title: 'Id', field: 'id', hidden: false, filtering: false },
        { title: 'TopMed Id', field: 'topMedId', hidden: false, filtering: false },
        { title: 'Nome', field: 'name', filtering: false },
        { title: 'E-mail', field: 'email', filtering: false },
        { title: 'CPF/CNPJ', field: 'document',
        render: (rowData: any) => {
            return maskCpf(rowData.document)
        }, filtering: false
        },
        { title: 'Data de Nascimento', field: 'birthDate', filtering: false },
        { title: 'Gênero', field: 'sex', filtering: false },
        { title: 'Celular', field: 'mobilePhone', filtering: false },
        { title: 'Status', field: 'status', filtering: false },
        { title: 'Criado em', field: 'createdAt', type: "date", filtering: false },
        { title: 'Atualizado em', field: 'updatedAt', type: "date", filtering: false },
    ])
    const columnsMobile = useRef([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'TopMed Id', field: 'topMedId', hidden: true },
        { title: 'Nome', field: 'name' },
        { title: 'E-mail', field: 'email' },
        { title: 'CPF/CNPJ', field: 'document'},
        { title: 'Celular', field: 'mobilePhone' },
        { title: 'Criado em', field: 'createdAt', type: "date" },
    ])
    const [payload, setPayload] = useState({
        id: "",
        topMedId: "",
        topMedData: "",
        name: "",
        email: "",
        document: "",
        birthDate: "",
        sex: "",
        mobilePhone: "",
        status: "",
        createdAt: "",
        updatedAt: "",
        billingAddress: {
            street: "",
            number: "",
            state: "",
            city: "",
            complement: "",
            neighborhood: "",
            postalCode: ""
        },
        shippingAddress: {
            street: "",
            number: "",
            state: "",
            city: "",
            complement: "",
            neighborhood: "",
            postalCode: ""
        }
    })
    const [isCreateOrUpdate, setIsCreateOrUpdate] = useState(false)
    const [shippingEqualsBilling, setShippingEqualsBilling] = useState(false)
    const [customerUpdateTab, setCustomerUpdateTab] = useState(0)
    const [isLoading, setIsloading] = useState(false)
	const [isRequestMade, setIsRequestMade] = useState(false)
	const token:string = useAppSelector((state: RootState) => state.sessionReducer.session.signInUserSession.idToken.jwtToken);

    const handleOnSave = useCallback(() => {
        setIsCreateOrUpdate(false)
        setCustomerUpdateTab(0)
    }, [])

    const handleOnCancel = useCallback(() => {
        setIsCreateOrUpdate(false)
        setCustomerUpdateTab(0)
    }, [])

    useEffect(() => {
        if (!isRequestMade) {
            setIsloading(true)
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            instanceTeleclinica.get(`/customers`, config)
            .then((res) => {
                setIsloading(false)
                setData(res.data.customers)
                setIsRequestMade(true)
            })
        }
      }, [data])

    return(
        <CustomersContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 15,
                alignItems: "center",
                width: "15%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    COMPRADORES
                </Typography>
            </Box>
            <CustomTable
                title="Lista de compradores (customer)"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={data}
                isLoading={isLoading}
                options={{
                    filtering: true
                }}
                style={{margin: "0 2% 0 2%"}}
                actions={[
                    {
                        icon: () => <Add />,
                        tooltip: 'Adicionar',
                        isFreeAction: true,
                        onClick: () => {setIsCreateOrUpdate(!isCreateOrUpdate)},
                        disabled: true
                    }
                ]}
            />
            <Dialog open={isCreateOrUpdate} maxWidth={false}>
                <DialogTitle id={"mainLabel"} sx={{
                    textAlign: "center",
                    fontSize: 24
                }}>
                    Informações gerais do cliente
                </DialogTitle>
                <DialogContent sx={{width: "95vw", height: "80vh"}}>
                   
                </DialogContent>
                <DialogActions sx={{width: "100%", alignItems: "flex-end"}}>
                    <Button id="save" variant="contained" fullWidth  sx={{width: "20%"}}
                        onClick={handleOnSave}
                    >
                        Salvar
                    </Button>
                    <Button variant="contained" color="error"  fullWidth  sx={{width: "20%"}}
                        onClick={handleOnCancel}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Backdrop
                sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </CustomersContainer>
    )
}

export default Customers;