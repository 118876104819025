import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	GET_SESSION_SUCCESS,
	GET_SESSION_ERROR,
	SESSION_CHECKED_SUCCESS,
	SESSION_CHECKED_ERROR,
	GET_USER_SESSION_SUCCESS,
	GET_USER_SESSION_ERROR,
	USER_CHECKED_SUCCESS,
	USER_CHECKED_ERROR,
  CLEAR_SESSION,
} from './actionTypes';

const initialState = {
  authenticated: false,
  session: null,
  sessionChecked: false,
  user: null,
  userChecked: false
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    [GET_SESSION_SUCCESS]: (
      state, {
        payload: { session }
      }: PayloadAction<{ session: any }>
    ) => {
      state.authenticated = true;
      state.session = session;
    },
    [GET_SESSION_ERROR]: (state) => {
      state.authenticated = false;
      state.session = null;
    },
		[SESSION_CHECKED_SUCCESS]: (state) => {
      state.sessionChecked = true;
    },
    [SESSION_CHECKED_ERROR]: (state) => {
      state.sessionChecked = true;
    },
		[GET_USER_SESSION_SUCCESS]: (state, {
      payload: { user }
    }: PayloadAction<{ user: any }>) => {
      state.user = user;
    },
    [GET_USER_SESSION_ERROR]: (state) => {
      state.user = null
    },
		[USER_CHECKED_SUCCESS]: (state) => {
      state.userChecked = true
    },
    [USER_CHECKED_ERROR]: (state) => {
      state.userChecked = true
    },
    [CLEAR_SESSION]: (state) => {
      state = initialState
    },
  }
})

export const sessionActions = sessionSlice.actions;
export default sessionSlice.reducer;