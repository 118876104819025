import { Navigate, Outlet } from 'react-router-dom';

interface PrivateRoutesProps {
    authenticated: boolean;
    unauthenticatedRoute: string;
}

const PrivateRoutes = ({authenticated, unauthenticatedRoute}: PrivateRoutesProps) => {
    return (
        authenticated ? 
        <Outlet/>
        :
        <Navigate to={unauthenticatedRoute} replace/>
    );
};

export default PrivateRoutes;