import { createSlice } from '@reduxjs/toolkit'
import { TOGGLE_IS_LOADING } from '../../app/actionTypes'

const initialState = {
  isLoading: false
}

const splashSlice = createSlice({
  name: 'splash',
  initialState,
  reducers: {
    [TOGGLE_IS_LOADING]: (state) => {
      state.isLoading = !state.isLoading
    },
  }
})

export const splashActions = splashSlice.actions;
export default splashSlice.reducer;