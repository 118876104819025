import React, { PropsWithChildren, useRef, useState } from "react";
import { PortabilityContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Box, Typography } from "@mui/material";
import { maskCpf, maskPhone } from "../../libs/masks";

interface Props extends PropsWithChildren{
}

function Portability(props: Props){
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)

    const columns = useRef([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Número', field: 'phoneNumber',
            render: (rowData: any) => {
                return maskPhone(rowData.phoneNumber)
            }    
        },
        { title: 'Número portado', field: 'portedPhoneNumber',
            render: (rowData: any) => {
                return maskPhone(rowData.portedPhoneNumber)
            }
        },
        { title: 'Nome Cliente', field: 'customer.name' },
        { title: 'CPF/CNPJ Cliente', field: 'customer.document',
            render: (rowData: any) => {
                return maskCpf(rowData.customer.document)
            }
        },
        { title: 'Autorizada?', field: 'authorized', type: "boolean" },
        { title: 'Status', field: 'status',
            lookup: {
                "requested": "Requisitada",
                "denied": "Negada",
                "fulfilled": "Realizada"
            }
        },
        { title: 'Data de agendamento', field: 'scheduledDate', type: "date" },
        { title: 'Criado em', field: 'createdAt', type: "date" },
    ])

    return(
        <PortabilityContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "15%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    PORTABILIDADE
                </Typography>
            </Box>
            <CustomTable
                title="Lista de portabilidades"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={[]}
                options={{
                    filtering: false
                }}
                style={{margin: "0 2% 0 2%"}}
                onRowAdd={(newRowData: any) => {
                    return Promise.resolve()
                }}
            />
        </PortabilityContainer>
    )
}

export default Portability;