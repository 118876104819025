export const convertToArray = (object: any) => {
    const array: any = []
  
    for (const chave in object) {
      if (object.hasOwnProperty(chave) && object[chave] === true) {
        array.push(switchKeyName(chave));
      }
    }
  
    return array
  }
  
  const switchKeyName = (value:string) => {
    switch(value){
      case "creditCard":
        return "CREDIT_CARD";
      case "pix":
        return "PIX"
      case "monthly":
        return "MONTHLY"
      case "yearly":
        return "YEARLY"
    }
  }