import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { UserManagementContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Add } from "@mui/icons-material";
import { 
    Backdrop, Box, Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Typography, Autocomplete
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { maskCpf, maskPhone } from "../../libs/masks";
import { useAppDispatch } from "../../app/hooks";
import { createUser, deleteUser, listUser, updateUser } from "./slice";
import { User } from "../../services/user";
import { listGroup } from "../GroupManagement/slice";

interface Props extends PropsWithChildren{
}
const validation = (value: string, fieldName: string) => {
    switch (fieldName) {
        case "name":
            return value.length < 3 || !value.includes(' ')
        case "email":
            return value.length < 3 || !value.includes('@')
        case "document":
            return value.length !== 14 && value.length !== 18
        case "mainPhone":
            return value.length < 14
        case "street":
            return value.length < 3
        case "number":
            return !value.length
        case "state":
            return value.length < 2
        case "city":
            return value.length < 3
        case "complement":
            return false
        case "neighborhood":
            return value.length < 3
        case "postalCode":
            return value.length < 8
        default:
            return true
    }
}

const mockClients = [
    {
        id: 1,
        name: "Administradores",
    },
    {
        id: 2,
        name: "Operador",
    }
]

export const columnsUserManagement = [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'IdCognito', field: 'cognitoId', hidden: true },
    { title: 'Usuário', field: 'name' },
    { title: 'Tipo', field: 'type', hidden: true },
    { title: 'Telefone', field: 'phone' },
    { title: 'E-mail', field: 'email' },
    { title: 'Documento de identificação', field: 'document' },
    { title: 'Status', field: 'status', hidden: true },
    { title: 'Grupo', field: 'group.name', hidden: true },
    { title: 'Data de cadastro', field: 'createdAt', type: "datetime" },
]

function UserManagement(props: Props){
    const appDispatch = useAppDispatch()
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)

    const columns = useRef(columnsUserManagement)

    const [payload, setPayload] = useState<User>({
        id: 0,
        name: "",
        email: "",
        document: "",
        phone: "",
        groupId: undefined
    })
    
    const [isCreateUser, setIsCreateUser] = useState(false)
    const [isUpdateUser, setIsUpdateUser] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [isLoadingGroup, setisLoadingGroup] = useState(false)
    const [openGroupOptions, setOpenGroupOptions] = useState(false)
    const tableRef = useRef<any>(null);

    const handleOnSave = useCallback(async () => {
        try {
            if (isCreateUser) {
                await appDispatch(createUser(payload)).unwrap()
                setIsCreateUser(false)
            }
            if (isUpdateUser) {
                await appDispatch(updateUser(payload)).unwrap()
                setIsUpdateUser(false)
            }
            tableRef.current&&tableRef.current.onQueryChange()
        } catch (error) {
            
        }
    }, [payload])

    const handleOnCancel = useCallback(() => {
        if (isCreateUser) {
            setIsCreateUser(false)
        }
        if (isUpdateUser) {
            setIsUpdateUser(false)
        }
    }, [])

    const [groupOptions, setGroupOptions] = useState([])
    useEffect(() => {
        if (!openGroupOptions) {
            setisLoadingGroup(true)
            appDispatch(listGroup({page: 0, pageSize: 100})).unwrap()
            .then((result: any) => {
                setGroupOptions(result.data);
                setisLoadingGroup(false)
            })
            .catch((error: any) => {
                console.error(error)
            })
        }
    }, [openGroupOptions]);
    
    return(
        <UserManagementContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "16%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    USUÁRIOS DA PLATAFORMA
                </Typography>
            </Box>
            <CustomTable
                title="Usuários da plataforma"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={async (query: any) => await appDispatch(listUser(query)).unwrap()}
                options={{
                    filtering: false
                }}
                style={{margin: "0 2% 0 2%"}}
                actions={[
                    {
                        icon: () => <Add />,
                        tooltip: 'Adicionar',
                        isFreeAction: true,
                        onClick: () => {setIsCreateUser(!isCreateUser)},
                    }
                ]}
                standartTableRef={tableRef}
                onRowUpdate={(rowData: any) => {
                    setPayload({
                        id: rowData.id,
                        name: rowData.name,
                        email: rowData.email,
                        document: rowData.document,
                        phone: rowData.phone,
                        groupId: rowData.group.id
                    })
                    setIsUpdateUser(true)
                }}
                onRowDelete={async (rowData: any) => {
                    try {
                        await appDispatch(deleteUser(rowData.id)).unwrap()
                    } catch (error) {
                    }
                }}
                onChangePage={(_page: number, _pageSize: number) => {
                    setPage(_page)
                    setPageSize(_pageSize)
                }}
            />
            <Dialog open={isCreateUser || isUpdateUser} maxWidth={false}>
                <DialogTitle id={"mainLabel"} sx={{
                    textAlign: "center",
                    fontSize: 24
                }}>
                    Usuário da plataforma
                </DialogTitle>
                <DialogContent>
                    <Grid container padding={"10px 0 0 0"}>
                        <Grid xs={4} m={1}>
                            <TextField id="name" label="Nome" variant="outlined" fullWidth
                                value={payload.name} onChange={(e) => setPayload({...payload, name: e.target.value})}
                                error={validation(payload.name, "name")} helperText="* Este campo é obrigatório"
                            />
                        </Grid>
                        <Grid xs={3} m={1}>
                            <TextField id="document" label="CPF/CNPJ" variant="outlined" fullWidth
                                value={payload.document} onChange={(e) => setPayload({...payload, document: maskCpf(e.target.value)})}
                                error={validation(payload.document, "document")} helperText="* Este campo é obrigatório"
                            />
                        </Grid>
                        <Grid xs={4} m={1}>
                            <TextField id="email" label="E-mail" variant="outlined" fullWidth
                                value={payload.email} onChange={(e) => setPayload({...payload, email: e.target.value})}
                                error={validation(payload.email, "email")} helperText="* Este campo é obrigatório"
                            />
                        </Grid>
                        <Grid xs={3} m={1}>
                            <TextField id="phone" label="Telefone" variant="outlined" fullWidth
                                value={payload.phone} onChange={(e) => setPayload({...payload, phone: maskPhone(e.target.value)})}
                                error={validation(payload.phone, "phone")} helperText="* Este campo é obrigatório, Ex: 55 11 912345678"
                            />
                        </Grid>
                        <Grid xs={3} m={1}>
                            <Autocomplete
                                id="groupSearch"
                                fullWidth
                                open={openGroupOptions}
                                onOpen={() => {
                                    setOpenGroupOptions(true);
                                }}
                                onClose={() => {
                                    setOpenGroupOptions(false);
                                }}
                                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                getOptionLabel={(option: any) => `${option.name}`}
                                options={groupOptions}
                                loading={isLoadingGroup}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Grupos"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingGroup ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                                onChange={(_, newValue: any) => {
                                    if (newValue) {
                                        setPayload({...payload, groupId: newValue.id});
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{width: "100%", alignItems: "flex-end"}}>
                    <Button id="save" variant="contained" fullWidth  sx={{width: "20%"}}
                        onClick={handleOnSave}
                    >
                        Salvar
                    </Button>
                    <Button variant="contained" color="error"  fullWidth  sx={{width: "20%"}}
                        onClick={handleOnCancel}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </UserManagementContainer>
    )
}

export default UserManagement;