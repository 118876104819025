import React, { PropsWithChildren, useRef, useState } from "react";
import { CompanyContainer } from "./styles";
import CustomTable from "../../components/CustomTable";
import { Box, Typography } from "@mui/material";

interface Props extends PropsWithChildren{
}

function Company(props: Props){
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)

    const columns = useRef([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Empresa', field: 'nome' },
        { title: 'CNPJ/Tax ID', field: 'telefoneContato' },
        { title: 'Data de cadastro', field: 'emailContato', type: "datetime" },
    ])

    return(
        <CompanyContainer maxWidth={false} disableGutters>
            <Box style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 5,
                alignItems: "center",
                width: "15%", height: 100, minWidth: 200
            }}>
                <Typography fontSize={20}>
                    EMPRESAS CADASTRADAS
                </Typography>
            </Box>
            <CustomTable
                title="Lista de empresas cadastradas"
                pageSizeOptions={[10,50,100]}
                pageSize={pageSize}
                page={page}
                columns={columns.current}
                getData={[]}
                options={{
                    filtering: false
                }}
            />
        </CompanyContainer>
    )
}

export default Company;