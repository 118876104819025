import React, { PropsWithChildren, useState } from "react";
import { CustomerCatalogContainer } from "./styles";
import Grid from '@mui/material/Unstable_Grid2';
import { Autocomplete, CircularProgress, TextField, Typography } from "@mui/material";
import TransferList from "../../components/TransferList";

interface Props extends PropsWithChildren{
}

function CustomerCatalog(props: Props){
    const [openProductOptions, setOpenProductOptions] = useState(false)
    const [isProductLoading, setIsProductLoading] = useState(false)
    const [payload, setPayload] = useState({
        customerId: 0
    })
    return(
        <CustomerCatalogContainer maxWidth={false}>
            <Grid container>
                <Grid xs={12} m={1}>
                    <Typography>
                        Selecione o cliente: 
                    </Typography>
                </Grid>
                <Grid xs={5} m={1}>
                    <Autocomplete
                        id="customerSearch"
                        fullWidth
                        open={openProductOptions}
                        onOpen={() => {
                            setOpenProductOptions(true);
                        }}
                        onClose={() => {
                            setOpenProductOptions(false);
                        }}
                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        getOptionLabel={(option: any) => `${option.product_name}`}
                        options={[]}
                        loading={isProductLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Cliente"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isProductLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                        onChange={(_, newValue: any) => {
                            if (newValue) {
                                setPayload({...payload, customerId: newValue.id});
                            }
                        }}
                    />
                </Grid>
                <Grid xs={12} m={1}>
                    <Typography>
                        Selecione os produtos deste cliente: 
                    </Typography>
                </Grid>
                <Grid xs={12} m={1}>
                    <TransferList
                    />
                </Grid>
            </Grid>
        </CustomerCatalogContainer>
    )
}

export default CustomerCatalog;