import React, { PropsWithChildren, useCallback, useState } from "react";
import { DashboardContainer } from "./styles";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

interface Props extends PropsWithChildren{
}


function Dashboard(props: Props){

    return(
        <DashboardContainer maxWidth={false} disableGutters>
            
        </DashboardContainer>
    )
}

export default Dashboard;