import { API, Auth } from "aws-amplify";

export interface Group {
    id?: number;
    name: string;
    description: string;
    createdAt?: string;
    updatedAt?: string;
}

export async function createGroup(group: Group){
    const response = await API.post('tex', `/groups`, {
        body: group,
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export interface ListQueryStringParameters extends Record<string, string> {
    limit: string;
    offset: string;
    search: string;
    filters: string;
    orderByCollection: string;
}

export interface ListResult {
    limit: number;
    offset: number;
    totalCount: number;
    groups: any[];
}

export async function listGroup(queryStringParameters: ListQueryStringParameters): Promise<ListResult>{
    const params = new URLSearchParams(queryStringParameters);
    const response = await API.get('tex', `/groups?${params}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function readGroup(groupId: number){
    const response = await API.get('tex', `/groups/${groupId}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function deleteGroup(groupId: number){
    const response = await API.del('tex', `/groups/${groupId}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function updateGroup(group: Group){
    const response = await API.put('tex', `/groups/${group.id}`, ({
        body: group,
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    }));
    return response;
}

export interface ListResultGroupPermissions {
    limit: number;
    offset: number;
    totalCount: number;
    groupPermissions: any[];
}

export async function listGroupPermissions(queryStringParameters: ListQueryStringParameters, groupId: number): Promise<ListResultGroupPermissions>{
    const params = new URLSearchParams(queryStringParameters);
    const response = await API.get('tex', `/groups/${groupId}/permissions?${params}`, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    });
    return response;
}

export async function updateGroupPermission(groupPermission: any){
    const response = await API.put('tex', `/groups/permissions/${groupPermission.id}`, ({
        body: {
            create: groupPermission.create,
            update: groupPermission.update,
            read: groupPermission.read,
            delete: groupPermission.delete,
            enabled: groupPermission.enabled
        },
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        }
    }));
    return response;
}
